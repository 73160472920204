@use "global" as *;

.p-create__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.p-create__img {
  @include mq(md) {
    order: 2;
    width: 50%;
    flex: 0 0 auto;
  }
}

.p-create__body {
  width: 86.66%;
  flex: 0 0 auto;

  @include mq(md) {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    right: rem(-50);
  }
}

.p-create__title {
  font-size: rem(20);
  margin-bottom: rem(10);
  letter-spacing: 0.15em;

  @include mq(md) {
    font-size: rem(24);
    margin-bottom: rem(25);
  }
}

.p-create__lead {
  font-size: rem(20);
  line-height: 1.5;
  margin-bottom: rem(20);
  color: #123269;
  letter-spacing: 0.2em;

  @include mq(md) {
    margin-bottom: rem(18);
    font-size: rem(30);
  }
}

.p-create__en {
  font-size: rem(12);
  margin-bottom: rem(10);
  letter-spacing: 0.04em;
  color: #bababa;

  @include mq(md) {
    margin-bottom: rem(30);
    font-size: rem(18);
  }
}

.p-create__text {
  font-size: rem(14);
  line-height: 2;
  letter-spacing: 0.12em;
}