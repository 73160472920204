@use "global" as *;

.p-lead__lead {
  font-size: rem(20);
  line-height: 1.5;
  letter-spacing: 0.16em;
  text-align: center;
  color: #123269;
  margin-bottom: rem(50);

  @include mq(md) {
    font-size: rem(26);
    line-height: 2;
    margin-bottom: rem(80);
  }
}

.p-lead__lead img {
  width: rem(282);
  margin: 0 auto;

  @include mq(md) {
    width: rem(457);
  }
}

.p-lead__text {
  font-size: rem(12);
  line-height: 2.5;
  letter-spacing: 0.06em;
  text-align: center;
  margin-top: rem(-30);

  @include mq(md) {
    font-size: rem(14);
    margin-top: rem(-60);
  }
}