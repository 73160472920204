@use "global" as *;

.p-guideline {
  padding: rem(86) 0 rem(52) 0;

  @include mq(md) {
    padding: rem(100) 0 rem(140) 0;
  }
}

.p-guideline img {
  margin: 0 auto;
}