@use "global" as *;

.p-eco {
  padding-top: rem(38);
  padding-bottom: rem(50);
  background-color: #eaeaea;

  @include mq(md) {
    padding-top: rem(90);
    padding-bottom: rem(90);
  }
}

.p-eco__wrapper {
  @include mq(md) {
    display: flex;
  }
}

.p-eco__body {
  @include mq(md) {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.p-eco__title {
  max-width: rem(230);
  margin-bottom: rem(40);

  @include mq(md) {
    max-width: 100%;
  }
}

.p-eco__lead {
  font-size: rem(20);
  line-height: 1.8;
  letter-spacing: 0.2em;
  color: #123269;
  margin-bottom: rem(25);
}

.p-eco__img {
  margin-bottom: rem(30);
  margin-right: calc(50% - 50vw);

  @include mq(md) {
    width: 50%;
    margin: 0;
  }
}

.p-eco__img img {
  @include mq(md) {
    width: 100%;
    object-fit: cover;
  }
}

.p-eco__text {
  line-height: 2;
  font-size: rem(12);
  letter-spacing: 0.06em;
}