@use "global" as *;

.c-contact-card {
  padding: 0 rem(15);
}

.c-contact-card__title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: rem(22);
  letter-spacing: 0.15em;
  margin-bottom: rem(30);
  line-height: 1.2;
}

.c-contact-card__title .en {
  font-family: $font-sub;
  font-size: rem(13);
  letter-spacing: 0.05em;
  margin-bottom: rem(10);
  text-transform: capitalize;
}

.c-contact-card__text {
  font-size: rem(12);
  letter-spacing: 0.05em;
  line-height: 2.2;
  text-align: center;
  margin-bottom: rem(40);
}

.c-contact-card__button {
  width: 90%;
  margin: 0 auto;
}