@use "global" as *;


.p-safety {
  padding-bottom: rem(40);
}

.p-safety__title {
  max-width: rem(177);
  margin-bottom: rem(40);

  @include mq(md) {
    max-width: 100%;
  }
}

.p-safety__wrapper {
  @include mq(md) {
    display: flex;
    align-items: center;
  }
}

.p-safety__img {


  @include mq(md) {
    width: 50%;
  }
}

.p-safety__img img {
  width: 100%;
  max-width: rem(504);
  box-shadow: 0 0 rem(10) rgba($color: $black, $alpha: 0.15);
}

.p-safety__body {
  margin-top: rem(30);

  @include mq(md) {
    width: 50%;
    margin-left: rem(60);
    margin-top: 0;
  }
}