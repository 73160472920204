@use "global" as *;

.p-aside__block+.p-aside__block {
  margin-top: rem(50);
}

.p-aside__title {
  font-family: $font-sub;
  font-size: rem(14);
  letter-spacing: 0.15em;
  color: #123269;
  font-weight: bold;
  padding-bottom: rem(15);
  margin-bottom: rem(15);
  border-bottom: 1px solid #e0e0e0;
  line-height: 1.2;
}

.p-aside__item:nth-child(1n+2) {
  margin-top: rem(15);
}

.p-aside__item a {
  font-size: rem(13);
  letter-spacing: 0.05em;
}