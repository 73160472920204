@use "global" as *;

.p-news {
  padding: rem(87) 0 rem(70) 0;
  background-color: #eaeaea;

  @include mq(md) {
    padding: rem(48) 0 rem(120) 0;
  }
}

.p-news__title {
  margin-bottom: rem(20);
  font-size: rem(20);
  position: relative;
}

.p-news__title img {
  width: rem(178);
  height: rem(53);

  @include mq(md) {
    width: rem(347);
    height: rem(103);
  }
}

.p-news__more {
  font-size: rem(12);
  position: absolute;
  letter-spacing: 0.24em;
  border-bottom: 1px solid $black;
  padding-bottom: rem(4);
  right: 0;
  bottom: 0;
}

.p-news__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include mq(md) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.p-news__card {
  margin-top: rem(20);
}