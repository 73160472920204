@use 'global' as *;

.p-recruit {
  background-color: #123269;
  color: $white;

  @include mq(md) {
    background: linear-gradient(180deg, #eaeaea 5%, #123269 5%, #123269 95%, #eaeaea 95%);
  }
}

.p-recruit__inner {
  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: rem(1366);
    margin: 0 auto;
  }
}

.p-recruit__img {
  @include mq(md) {
    width: 66.47%;
    margin-left: -6.88%;
  }
}

.p-recruit__body {
  padding: rem(30) 6.67%;

  @include mq(md) {
    width: 33.53%;
    margin: 0;
    padding: 0;
  }
}

.p-recruit__title {
  margin-bottom: rem(20);

  @include mq(md) {
    margin-bottom: rem(8);
  }
}

.p-recruit__title-img {
  width: rem(236);

  @include mq(md) {
    width: 100%;
  }
}

.p-recruit__subtitle {
  max-width: rem(310);
  width: 100%;
  margin-top: rem(8);
}

.p-recruit__text {
  font-size: rem(12);
  margin-bottom: rem(20);
  letter-spacing: 0.05em;
  line-height: 2.2;

  @include mq(md) {
    font-size: rem(13);
    margin-bottom: rem(30);
  }
}