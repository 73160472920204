@use 'global' as *;

.p-banner {
  padding: rem(25) 0;

  @include mq(md) {
    padding: rem(60) 0;
  }
}

.p-banner__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-banner__item01 {
  margin-bottom: rem(15);
  position: relative;

  @include mq(md) {
    width: 48.49%;
  }
}

.p-banner__logo {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.p-banner__item02 {
  width: 48%;
  position: relative;

  @include mq(md) {
    width: 22.88%;
  }
}

.p-banner__item03 {
  width: 48%;
  position: relative;

  @include mq(md) {
    width: 22.88%;
  }
}