@use 'sass:math';

// インナー幅
$innerWidth: 1366px;

// 余白
$padding-pc: 12px;
$padding-sp: 20px;

// フォント
$font-main: "noto-sans-cjk-jp", YuGothic, "Yu Gothic", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
$font-sub: "vdl-v7mincho", sans-serif;

// 色指定
$black: #212529;
$white: #fff;

// ブレイクポイント
$breakpoints: (
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1440,
);

// メディアクエリ
$mediaquerys: (
  sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
);

// メディアクエリを "@include mq(){}" で書くことができる
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}