@use "global" as *;

.p-col-card__title {
  margin-top: rem(40);
  font-size: rem(18);
  letter-spacing: 0.2em;
  color: #123269;
  margin-bottom: rem(30);
  line-height: 2;
  font-weight: 500;
}

.p-col-card__text {
  font-size: rem(13);
  letter-spacing: 0.11em;
  line-height: 2.2;
}