@use "global" as *;

.p-quality {
  padding-top: rem(40);
  padding-bottom: rem(40);

  @include mq(md) {
    padding-top: rem(120);
    padding-bottom: rem(120);
  }
}

.p-quality__wrapper {
  @include mq(md) {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
  }
}

.p-quality__title {
  max-width: rem(211);
  margin-bottom: rem(40);

  @include mq(md) {
    max-width: 100%;
  }
}

.p-quality__img {
  margin-bottom: rem(20);

  @include mq(md) {
    margin-bottom: 0;
    width: 50%;
  }
}

.p-quality__img img:first-of-type {
  margin-left: auto;
  box-shadow: 0 0 rem(10) rgba($color: $black, $alpha: 0.15);

  @include mq(md) {
    max-width: rem(452);
  }
}

.p-quality__img img:nth-of-type(2) {
  width: 61%;
  max-width: rem(280);
  margin-top: rem(-90);
  margin-right: rem(-25);
  margin-left: auto;
  box-shadow: 0 0 rem(10) rgba($color: $black, $alpha: 0.15);

  @include mq(md) {
    margin-left: rem(-30);
    margin-right: auto;
  }
}

.p-quality__body {
  margin-top: rem(30);

  @include mq(md) {
    width: 50%;
    margin-top: 0;
  }
}

.p-quality__lead {
  font-size: rem(15);
  line-height: 1.7;
  letter-spacing: 0.06em;
  margin-bottom: rem(10);
  color: #123269;
}

.p-quality__text {
  font-size: rem(12);
  line-height: 2;
  letter-spacing: 0.06em;

  @include mq(md) {
    font-size: rem(13);
  }
}

.p-quality__box {
  background-color: #f8f8f8;
  padding: rem(15) rem(20);
  margin-bottom: rem(10);
  margin-top: rem(20);

  @include mq(md) {
    margin-top: rem(25);
  }
}

.p-quality__box div {
  font-size: rem(12);
  display: flex;
  line-height: 2;
  letter-spacing: 0.06em;

  @include mq(md) {
    font-size: rem(13);
  }
}

.p-quality__box dt {
  width: rem(90);
  font-weight: 700;
}

.p-quality__box dd {
  margin-bottom: 0.5rem;
}

.p-quality__text a {
  color: #b4b4b4;
  text-decoration: underline;
}

.p-quality__button {
  margin-top: rem(25);

  @include mq(md) {
    display: none;
  }
}