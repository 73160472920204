@use "global" as *;

.c-sec-title span {
  display: inline-block;
  margin-top: rem(20);
  font-size: rem(24);
  letter-spacing: 0.16em;
  font-weight: 500;
  line-height: 1.2;

  @include mq(md) {
    margin-top: rem(30);
  }
}