@use "global" as *;

.p-philosophy {
  padding: rem(120) 0 rem(24) 0;
}

.p-philosophy__title {
  text-align: center;
}

.p-philosophy__title img {
  margin: 0 auto;
}

.p-philosophy__lead {
  font-size: rem(20);
  line-height: 1.5;
  letter-spacing: 0.16em;
  margin: rem(30) 0 rem(20) 0;
  color: #123269;
  text-align: center;

  @include mq(md) {
    font-size: rem(26);
    line-height: 2;
  }
}

.p-philosophy__text {
  font-size: rem(12);
  line-height: 2.5;
  letter-spacing: 0.06em;
  margin-top: rem(20);

  @include mq(md) {
    font-size: rem(14);
    text-align: center;
  }
}