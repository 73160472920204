@use "global" as *;

.c-requirements-card__title {
  border-bottom: 1px solid #d9d9d9;
  padding: rem(20) 0;
  color: #123269;
  font-size: rem(18);
  line-height: 1.7;
  letter-spacing: 0.12em;
  position: relative;

  @include mq(md) {
    padding: rem(30) 0;
    border-bottom: rem(2) solid $white;
  }
}

.c-requirements-card__title span {
  color: #9b9b9b;
  font-size: rem(12);
  display: block;

  @include mq(md) {
    display: inline-block;
  }
}

.c-requirements-card__title::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #123269;
  height: 1px;
  width: rem(12);
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @include mq(md) {
    width: rem(18);
  }
}

.c-requirements-card__title::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #123269;
  height: 1px;
  width: rem(12);
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);

  @include mq(md) {
    width: rem(18);
  }
}

.c-requirements-card__box {
  display: none;
  padding: rem(5) 0;

  @include mq(md) {
    padding: rem(15) rem(20);
  }
}

.c-requirements-card__box tr {
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex-wrap: wrap;
  padding: rem(15) 0;

  @include mq(md) {
    border-bottom: none;
    flex-wrap: nowrap;
  }
}

.c-requirements-card__box th {
  padding-bottom: rem(8);
  font-size: rem(15);
  line-height: 2;
  letter-spacing: 0.12em;
  display: block;
  width: 100%;
  text-align: left;

  @include mq(md) {
    width: rem(160);
    flex-shrink: 0;
  }
}

.c-requirements-card__box td {
  letter-spacing: 0.06em;
  font-size: rem(12);
  line-height: 2;
}