@use "global" as *;

.p-page-pan {
  display: none;
  margin-top: rem(100);
  padding: rem(30) rem(60) 0;

  @include mq(md) {
    display: block;
  }
}

.p-page-pan span {
  color: #a8a8a8;
  font-size: rem(11);
  line-height: 1;
  letter-spacing: 0.16em;
}