@use "global" as *;

.c-sec-card {
  padding: 0 rem(12);

  @include mq(md) {
    padding: 0 rem(44);
  }
}

.c-sec-card__title {
  margin-bottom: rem(30);
}

.c-sec-card__title.company {
  margin-top: rem(-60);

  @include mq(md) {
    margin-top: 0;
  }
}

.c-sec-card__text {
  font-size: rem(13);
  letter-spacing: 0.06em;
  margin-bottom: rem(40);
  line-height: 2.76;
}