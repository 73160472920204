@use "global" as *;

.c-interview-card {
  padding: rem(50) rem(20) 0 rem(20);
  position: relative;
}

.c-interview-card::before {
  content: "";
  display: block;
  width: 22%;
  height: 65%;
  background-color: #123269;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  @include mq(md) {
    display: none;
  }
}

.c-interview-card:nth-of-type(odd)::before {
  right: auto;
  left: 0;
}

.c-interview-card a {
  width: calc(100% - #{rem(40)});
  max-width: rem(540);
  position: relative;
  display: block;

  @include mq(md) {
    width: 100%;
  }
}

.c-interview-card:nth-of-type(odd) a {
  margin-left: auto;

  @include mq(md) {
    margin-left: 0;
  }
}

.c-interview-card__img {
  margin-bottom: rem(30);
}

.c-interview-card__img img {
  box-shadow: 0 0 rem(10) rgba($color: #000000, $alpha: 0.15);
}

.c-interview-card__title {
  color: #123269;
  font-size: rem(16);
  line-height: 1.7;
  letter-spacing: 0.16em;
  margin-bottom: rem(15);
  font-weight: 500;

  @include mq(md) {
    color: $black;
  }
}

.c-interview-card:nth-of-type(odd) .c-interview-card__title {
  margin-left: rem(-15);

  @include mq(md) {
    margin-left: 0;
  }
}

.c-interview-card__name {
  font-size: rem(12);
  line-height: 1.7;
  letter-spacing: 0.16em;
  font-weight: 500;

  @include mq(md) {
    color: #a2a2a2;
  }
}

.c-interview-card:nth-of-type(odd) .c-interview-card__name {
  margin-left: rem(-15);

  @include mq(md) {
    margin-left: 0;
  }
}