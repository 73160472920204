@charset "UTF-8";
html {
  font-size: 100%;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.1510791367vw;
  }
}
@media (min-width: 1390px) {
  html {
    font-size: 100%;
  }
}

body {
  font-family: "noto-sans-cjk-jp", YuGothic, "Yu Gothic", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
  color: #212529;
}

body.works {
  background-color: #eaeaea;
}

@media screen and (min-width: 768px) {
  a,
  button {
    transition: 0.3s;
  }
  a:hover,
  button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

/*****************************
* A Modern CSS Reset (https://github.com/hankchizljaw/modern-css-reset)
* 上記に、ul要素,ol要素,a要素への記述追加
*****************************/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
li {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.l-about-sdgs {
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .l-about-sdgs {
    margin-top: 6.25rem;
  }
}

.l-greeting {
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .l-greeting {
    margin-bottom: 7.5rem;
  }
}

.l-inner {
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .l-inner {
    max-width: 1390px;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.l-inner.mid {
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .l-inner.mid {
    max-width: 68.125rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.l-interview {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .l-interview {
    margin-top: 5rem;
  }
}

.l-news-list {
  margin-top: 5rem;
}

.l-philosophy {
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .l-philosophy {
    margin-bottom: 6.25rem;
  }
}

.l-spirit {
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .l-spirit {
    margin-bottom: 6.25rem;
  }
}

.c-about-sdgs-card__body {
  padding: 1.5625rem 1.25rem 3.125rem;
}
@media screen and (min-width: 768px) {
  .c-about-sdgs-card__body {
    width: 55%;
    flex: 0 0 auto;
    padding: 0;
  }
}

.c-about-sdgs-card__title {
  margin-bottom: 0.625rem;
  color: #123269;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2;
  letter-spacing: 0.06em;
}
@media screen and (min-width: 768px) {
  .c-about-sdgs-card__title {
    margin-bottom: 1.5625rem;
  }
}

.c-about-sdgs-card__text {
  margin-bottom: 1em;
  line-height: 2.3;
  font-size: 0.75rem;
  letter-spacing: 0.06em;
}

@media screen and (min-width: 768px) {
  .c-about-sdgs-card__img {
    width: 45%;
    padding-right: 3.75rem;
    padding-left: 2.75rem;
    flex: 0 0 auto;
  }
}

.c-button {
  color: #000;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.4375rem;
  border: 1px solid #b3b3b3;
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 0.24em;
  font-family: "vdl-v7mincho", sans-serif;
}
@media screen and (min-width: 768px) {
  .c-button {
    width: 15rem;
  }
}

.c-button::after {
  content: "";
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/common/button-arrow-white.svg) no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  position: absolute;
  top: 50%;
  right: -1.4375rem;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 0.25rem;
}

.c-button.ex {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}
@media screen and (min-width: 768px) {
  .c-button.ex {
    width: 15rem;
  }
}

.c-button.ex::after {
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/common/button-arrow-clear.svg) no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  position: absolute;
  top: 50%;
  right: -1.4375rem;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 0.25rem;
}

.c-chronology-card__img {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.c-chronology-card__body {
  padding: 3.5rem 0;
}

.c-chronology-card__list {
  position: relative;
  width: 86.66%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-chronology-card__list {
    width: 100%;
  }
}

.c-chronology-card__list::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #1c3265;
}

.c-chronology-card__item {
  position: relative;
  padding-left: 2.1875rem;
}

.c-chronology-card__item::before {
  content: "";
  display: block;
  position: absolute;
  left: -0.3125rem;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.3125rem;
  background-color: #123269;
}
@media screen and (min-width: 768px) {
  .c-chronology-card__item::before {
    top: 0.1875rem;
  }
}

.c-chronology-card__title {
  font-size: 0.9375rem;
  color: #123269;
  margin-bottom: 0.5rem;
  letter-spacing: 0.12em;
  line-height: 1.2;
}

.c-chronology-card__text {
  font-size: 0.8125rem;
  letter-spacing: 0.11em;
  line-height: 1.8;
}

@media screen and (min-width: 768px) {
  .c-chronology-card__item:nth-child(1n+2) {
    margin-top: 2.5rem;
  }
}

.c-contact-card {
  padding: 0 0.9375rem;
}

.c-contact-card__title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.375rem;
  letter-spacing: 0.15em;
  margin-bottom: 1.875rem;
  line-height: 1.2;
}

.c-contact-card__title .en {
  font-family: "vdl-v7mincho", sans-serif;
  font-size: 0.8125rem;
  letter-spacing: 0.05em;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
}

.c-contact-card__text {
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  line-height: 2.2;
  text-align: center;
  margin-bottom: 2.5rem;
}

.c-contact-card__button {
  width: 90%;
  margin: 0 auto;
}

.c-greeting-card__img {
  width: 86.66%;
  margin-bottom: 2.5rem;
  margin-left: calc(50% - 50vw);
}

.c-greeting-card__img.right {
  margin-left: auto;
  margin-right: calc(50% - 50vw);
}

.c-greeting-card__title {
  font-size: 1.125rem;
  letter-spacing: 0.2em;
  color: #123269;
  margin-bottom: 1.875rem;
  line-height: 2;
  font-weight: 500;
}

.c-greeting-card__text {
  font-size: 0.8125rem;
  letter-spacing: 0.11em;
  line-height: 2.2;
}

.c-greeting-card__sign {
  margin-top: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8125rem;
  letter-spacing: 0.11em;
}

.c-greeting-card__sign span {
  margin-right: 0.5rem;
}

.c-greeting-card__sign img {
  width: 8.625rem;
}

.c-interview-card {
  padding: 3.125rem 1.25rem 0 1.25rem;
  position: relative;
}

.c-interview-card::before {
  content: "";
  display: block;
  width: 22%;
  height: 65%;
  background-color: #123269;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .c-interview-card::before {
    display: none;
  }
}

.c-interview-card:nth-of-type(odd)::before {
  right: auto;
  left: 0;
}

.c-interview-card a {
  width: calc(100% - 2.5rem);
  max-width: 33.75rem;
  position: relative;
  display: block;
}
@media screen and (min-width: 768px) {
  .c-interview-card a {
    width: 100%;
  }
}

.c-interview-card:nth-of-type(odd) a {
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .c-interview-card:nth-of-type(odd) a {
    margin-left: 0;
  }
}

.c-interview-card__img {
  margin-bottom: 1.875rem;
}

.c-interview-card__img img {
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15);
}

.c-interview-card__title {
  color: #123269;
  font-size: 1rem;
  line-height: 1.7;
  letter-spacing: 0.16em;
  margin-bottom: 0.9375rem;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .c-interview-card__title {
    color: #212529;
  }
}

.c-interview-card:nth-of-type(odd) .c-interview-card__title {
  margin-left: -0.9375rem;
}
@media screen and (min-width: 768px) {
  .c-interview-card:nth-of-type(odd) .c-interview-card__title {
    margin-left: 0;
  }
}

.c-interview-card__name {
  font-size: 0.75rem;
  line-height: 1.7;
  letter-spacing: 0.16em;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .c-interview-card__name {
    color: #a2a2a2;
  }
}

.c-interview-card:nth-of-type(odd) .c-interview-card__name {
  margin-left: -0.9375rem;
}
@media screen and (min-width: 768px) {
  .c-interview-card:nth-of-type(odd) .c-interview-card__name {
    margin-left: 0;
  }
}

.c-news-card {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
@media screen and (min-width: 768px) {
  .c-news-card {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

.c-news-card a {
  display: block;
}

.c-news-card__img {
  aspect-ratio: 4/3;
  margin-bottom: 0.875rem;
}
@media screen and (min-width: 768px) {
  .c-news-card__img {
    margin-bottom: 1.875rem;
  }
}

.c-news-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-news-card__category {
  font-size: 0.625rem;
  letter-spacing: 0.12em;
  padding: 0.125rem 0.3125rem;
  border: 1px solid #123269;
  display: inline-block;
}

.c-news-card__title {
  font-size: 0.6875rem;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
  letter-spacing: 0.05em;
  line-height: 1.71;
}
@media screen and (min-width: 768px) {
  .c-news-card__title {
    font-size: 0.875rem;
    margin-top: 1.5625rem;
    margin-bottom: 0.9375rem;
  }
}

.c-news-card__time {
  display: block;
  font-family: "vdl-v7mincho", sans-serif;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  color: #123269;
}

.c-requirements-card__title {
  border-bottom: 1px solid #d9d9d9;
  padding: 1.25rem 0;
  color: #123269;
  font-size: 1.125rem;
  line-height: 1.7;
  letter-spacing: 0.12em;
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-requirements-card__title {
    padding: 1.875rem 0;
    border-bottom: 0.125rem solid #fff;
  }
}

.c-requirements-card__title span {
  color: #9b9b9b;
  font-size: 0.75rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .c-requirements-card__title span {
    display: inline-block;
  }
}

.c-requirements-card__title::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #123269;
  height: 1px;
  width: 0.75rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (min-width: 768px) {
  .c-requirements-card__title::before {
    width: 1.125rem;
  }
}

.c-requirements-card__title::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #123269;
  height: 1px;
  width: 0.75rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
@media screen and (min-width: 768px) {
  .c-requirements-card__title::after {
    width: 1.125rem;
  }
}

.c-requirements-card__box {
  display: none;
  padding: 0.3125rem 0;
}
@media screen and (min-width: 768px) {
  .c-requirements-card__box {
    padding: 0.9375rem 1.25rem;
  }
}

.c-requirements-card__box tr {
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex-wrap: wrap;
  padding: 0.9375rem 0;
}
@media screen and (min-width: 768px) {
  .c-requirements-card__box tr {
    border-bottom: none;
    flex-wrap: nowrap;
  }
}

.c-requirements-card__box th {
  padding-bottom: 0.5rem;
  font-size: 0.9375rem;
  line-height: 2;
  letter-spacing: 0.12em;
  display: block;
  width: 100%;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .c-requirements-card__box th {
    width: 10rem;
    flex-shrink: 0;
  }
}

.c-requirements-card__box td {
  letter-spacing: 0.06em;
  font-size: 0.75rem;
  line-height: 2;
}

.c-sdgs-card {
  position: relative;
}

.c-sdgs-card__title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1875rem;
  color: #fff;
  background-color: #123269;
  line-height: 1.5;
  padding: 1.25rem;
  letter-spacing: 0.16em;
}
@media screen and (min-width: 768px) {
  .c-sdgs-card__title {
    position: absolute;
    top: 1.875rem;
    left: 1.25rem;
    line-height: 2;
    padding: 0.3125rem 2.8125rem;
    background-color: transparent;
  }
}

@media screen and (min-width: 768px) {
  .c-sdgs-card__title::before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #123269;
    transform: skew(-25deg, 0);
    z-index: -1;
  }
}

.c-sdgs-card__wrapper {
  border: 1px solid #b3b3b3;
  padding: 1.5625rem 1.25rem 1.25rem;
}
@media screen and (min-width: 768px) {
  .c-sdgs-card__wrapper {
    padding: 6.25rem 2.8125rem 2.5rem;
    display: flex;
    align-items: flex-end;
  }
}

@media screen and (min-width: 768px) {
  .c-sdgs-card__body {
    width: 66.66666667%;
    flex: 0 0 auto;
    padding-right: 1.25rem;
  }
}

.c-sdgs-card__lead {
  font-size: 0.9375rem;
  margin-left: -0.5em;
  margin-bottom: 0.625rem;
  line-height: 2;
  letter-spacing: 0.06em;
  font-weight: bold;
}

.c-sdgs-card__text {
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.06em;
  max-width: 37.5rem;
  margin-bottom: 1.5625rem;
}

.c-sdgs-card__list {
  margin-bottom: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .c-sdgs-card__list {
    display: flex;
  }
}

.c-sdgs-card__list ul:first-of-type {
  width: 45%;
  padding-right: 1.25rem;
}

.c-sdgs-card__list li {
  font-size: 0.75rem;
  line-height: 2.3;
  letter-spacing: 0.06em;
  padding-left: 1em;
  text-indent: -1em;
  font-weight: bold;
}

.c-sdgs-card__list li::before {
  content: "■";
  color: rgb(18, 50, 105);
}

.c-sdgs-card__img {
  width: 100%;
  max-width: 17.8125rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-sdgs-card__img {
    width: 33.33333333%;
    flex: 0 0 auto;
  }
}

.c-sec-card {
  padding: 0 0.75rem;
}
@media screen and (min-width: 768px) {
  .c-sec-card {
    padding: 0 2.75rem;
  }
}

.c-sec-card__title {
  margin-bottom: 1.875rem;
}

.c-sec-card__title.company {
  margin-top: -3.75rem;
}
@media screen and (min-width: 768px) {
  .c-sec-card__title.company {
    margin-top: 0;
  }
}

.c-sec-card__text {
  font-size: 0.8125rem;
  letter-spacing: 0.06em;
  margin-bottom: 2.5rem;
  line-height: 2.76;
}

.c-sec-title span {
  display: inline-block;
  margin-top: 1.25rem;
  font-size: 1.5rem;
  letter-spacing: 0.16em;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .c-sec-title span {
    margin-top: 1.875rem;
  }
}

.c-work-card__img {
  aspect-ratio: 247/172;
}

.c-work-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-work-card__body {
  background-color: #fff;
  padding: 0.9375rem 1.1875rem 1.25rem;
}

.c-work-card__title {
  border-bottom: 1px solid #707070;
  margin-bottom: 0.625rem;
  padding-bottom: 0.625rem;
  font-size: 0.9375rem;
  line-height: 2;
  letter-spacing: 0.06em;
  color: #123269;
}
@media screen and (min-width: 768px) {
  .c-work-card__title {
    border-bottom: 1px solid #123269;
    padding-bottom: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
}

.c-work-card__text {
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.06em;
}
@media screen and (min-width: 768px) {
  .c-work-card__text {
    font-size: 0.6875rem;
  }
}

.p-able {
  padding-bottom: 3.125rem;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .p-able {
    padding-bottom: 6.25rem;
  }
}

.p-able__wrapper {
  padding-left: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .p-able__wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
  }
}

.p-able__img {
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-able__img {
    width: 50%;
    flex: 0 0 auto;
  }
}

.p-able__body {
  max-width: 33.75rem;
  width: calc(100% - 1.5625rem);
}
@media screen and (min-width: 768px) {
  .p-able__body {
    max-width: 30.1875rem;
    padding-left: 3.125rem;
    margin: 0 auto;
    width: 41.66666667%;
    flex: 0 0 auto;
  }
}

.p-able__title {
  margin-bottom: 0.625rem;
  color: #123269;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2;
  letter-spacing: 0.06em;
}

.p-able__text {
  margin-bottom: 1em;
  line-height: 2.3;
  letter-spacing: 0.06em;
  font-size: 0.75rem;
}

.p-able__button {
  max-width: calc(100% - 1.4375rem);
}

.p-about-sdgs {
  padding-top: 1.5625rem;
  padding-bottom: 3.125rem;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .p-about-sdgs {
    padding: 6.25rem 0;
  }
}

.p-about-sdgs__inner {
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .p-about-sdgs__inner {
    padding: 3.75rem 5rem 4.375rem;
  }
}

.p-about-sdgs__title {
  padding: 1.5625rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-about-sdgs__title {
    margin-bottom: 4.375rem;
  }
}

.p-about-sdgs__title img {
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .p-about-sdgs__card {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
  }
}

.p-achievement {
  padding: 5rem 0 0;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-achievement {
    padding: 5rem 0;
  }
}

.p-achievement::before {
  content: "";
  display: block;
  width: 15%;
  height: 100%;
  background: #123269;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .p-achievement__wrapper {
    display: flex;
    align-items: flex-end;
  }
}

.p-achievement__img {
  max-width: 33.75rem;
  margin-bottom: 1.25rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 768px) {
  .p-achievement__img {
    width: 45%;
    margin: 0;
    flex: 0 0 auto;
    padding: 0 2.75rem;
  }
}

.p-achievement__img img {
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.p-achievement__body {
  background-color: #fff;
  width: 100vw;
  padding: 1.875rem 1.5625rem 2.5rem 1.5625rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 768px) {
  .p-achievement__body {
    width: 45%;
    margin: 0 0 0 auto;
    padding-left: 0.625rem;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    flex: 0 0 auto;
  }
}

.p-achievement__title {
  font-size: 1.25rem;
  margin-bottom: 0.9375rem;
  line-height: 1;
  letter-spacing: 0.16em;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .p-achievement__title {
    font-size: 1.375rem;
    color: #123269;
    margin-bottom: 1.5625rem;
  }
}

.p-achievement__text {
  font-size: 0.75rem;
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-achievement__text {
    font-size: 0.8125rem;
    line-height: 2;
    letter-spacing: 0.12em;
    margin-bottom: 2.5rem;
  }
}

.p-achievement__btn:not(:last-child) {
  margin-bottom: 1.25rem;
}

.p-aside__block + .p-aside__block {
  margin-top: 3.125rem;
}

.p-aside__title {
  font-family: "vdl-v7mincho", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.15em;
  color: #123269;
  font-weight: bold;
  padding-bottom: 0.9375rem;
  margin-bottom: 0.9375rem;
  border-bottom: 1px solid #e0e0e0;
  line-height: 1.2;
}

.p-aside__item:nth-child(1n+2) {
  margin-top: 0.9375rem;
}

.p-aside__item a {
  font-size: 0.8125rem;
  letter-spacing: 0.05em;
}

.p-banner {
  padding: 1.5625rem 0;
}
@media screen and (min-width: 768px) {
  .p-banner {
    padding: 3.75rem 0;
  }
}

.p-banner__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-banner__item01 {
  margin-bottom: 0.9375rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-banner__item01 {
    width: 48.49%;
  }
}

.p-banner__logo {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.p-banner__item02 {
  width: 48%;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-banner__item02 {
    width: 22.88%;
  }
}

.p-banner__item03 {
  width: 48%;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-banner__item03 {
    width: 22.88%;
  }
}

.p-business {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .p-business {
    padding: 7.5rem 0;
    background-image: linear-gradient(90deg, transparent 0%, #123269 0%, #123269 10%, transparent 10%), repeating-linear-gradient(90deg, transparent, transparent 190px, rgba(226, 226, 226, 0.4) 190px, rgba(226, 226, 226, 0.4) 191px), repeating-linear-gradient(0deg, transparent, transparent 190px, rgba(226, 226, 226, 0.4) 190px, rgba(226, 226, 226, 0.4) 191px);
  }
}

@media screen and (min-width: 768px) {
  .p-business__wrapper {
    display: flex;
    align-items: center;
  }
}

.p-business__imgarea {
  position: relative;
  width: 84.3%;
  margin-top: 1.875rem;
  padding: 0 0.75rem;
}
@media screen and (min-width: 768px) {
  .p-business__imgarea {
    width: 43.84%;
    flex: 1 0 0%;
    margin-top: 0;
    padding: 0 2.75rem;
  }
}

.p-business__img02 {
  width: 54.19%;
  position: absolute;
  bottom: -8%;
  right: -10%;
}
@media screen and (min-width: 768px) {
  .p-business__img02 {
    right: 0;
  }
}

.p-business__imgarea img {
  box-shadow: 0.375rem 0.375rem 0.625rem rgba(0, 0, 0, 0.16);
}

.p-business__card {
  margin-top: 1.875rem;
  padding: 0 0.75rem;
}
@media screen and (min-width: 768px) {
  .p-business__card {
    width: 100%;
    flex: 1 0 0%;
    margin-top: 0;
    padding: 0 2.75rem;
  }
}

.p-chronology {
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .p-chronology {
    overflow: visible;
    padding-bottom: 6.25rem;
  }
}

.p-chronology__swiper {
  position: relative;
}

.swiper-control {
  position: absolute;
  top: 120vw;
  right: 3%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 5.625rem;
  height: 2.5rem;
}

.p-chronology__swiper-prev {
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/page/our_history/slider-prev.svg) no-repeat;
  background-position: center;
}

.p-chronology__swiper-next {
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/page/our_history/slider-next.svg) no-repeat;
  background-position: center;
  margin-left: 0.625rem;
}

.p-chronology__title {
  font-size: 1.375rem;
  letter-spacing: 0.16em;
  line-height: 2;
  margin-bottom: 1.25rem;
}

.p-chronology__wrapper {
  display: flex;
  height: 100%;
}

.p-chronology__body {
  width: 50%;
}

.p-chronology__imgarea {
  width: 50%;
  flex: 0 0 auto;
  position: relative;
}

.p-chronology__img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.p-chronology__img img {
  position: sticky;
  top: 6.25rem;
  left: 0;
}

.p-chronology__img.show:nth-child(1) {
  z-index: -2;
}

.p-col-card__title {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  letter-spacing: 0.2em;
  color: #123269;
  margin-bottom: 1.875rem;
  line-height: 2;
  font-weight: 500;
}

.p-col-card__text {
  font-size: 0.8125rem;
  letter-spacing: 0.11em;
  line-height: 2.2;
}

.p-company {
  padding: 8.75rem 0 3.125rem 0;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .p-company {
    padding: 6.25rem 0 7.5rem 0;
  }
}

.p-company__wrapper {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-company__wrapper {
    flex-direction: row;
  }
}

.p-company__card {
  order: 2;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-company__card {
    order: 1;
    width: 38.03%;
    flex: 1 0 0%;
    margin-top: 0;
  }
}

.p-company__img {
  order: 1;
  margin-top: 1.875rem;
  padding: 0 0.75rem;
}
@media screen and (min-width: 768px) {
  .p-company__img {
    order: 2;
    width: 49.23%;
    padding: 0 2.75rem;
    flex: 1 0 0%;
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-company__img img {
    height: 100%;
    object-fit: cover;
  }
}

.p-contact-form {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-contact-form {
    background-color: #fff;
    padding: 3.75rem;
  }
}

.p-contact-form__text {
  margin-bottom: 1.875rem;
  font-size: 0.9375rem;
  letter-spacing: 0.16em;
  font-weight: bold;
  color: #123269;
}
@media screen and (min-width: 768px) {
  .p-contact-form__text {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    letter-spacing: 0.06em;
    text-align: center;
  }
}

.p-contact-form__box {
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .p-contact-form__box {
    margin-bottom: 1.25rem;
    padding: 1.5625rem;
    border: 1px solid #f0f0f0;
  }
}

@media screen and (min-width: 768px) {
  .p-contact-form__box div {
    height: 5rem;
    overflow-y: scroll;
  }
}

.p-contact-form__lead {
  font-size: 0.75rem;
  font-weight: 600;
  color: #02436b;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-contact-form__lead {
    font-size: 0.875rem;
  }
}

.p-contact-form__list li {
  position: relative;
  font-size: 0.625rem;
  padding-left: 1.8em;
  line-height: 2;
}

.p-contact-form__list li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "・";
}

.p-contact {
  padding: 2.8125rem 0;
  background-color: #eaeaea;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .p-contact {
    padding: 7.375rem 0 5rem 0;
  }
}

.p-contact.news {
  background-color: #fff;
}

.p-contact__wrapper {
  background-color: #123269;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-contact__wrapper {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    display: flex;
    flex-wrap: wrap;
  }
}

.p-contact__card {
  padding-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-contact__card {
    width: 50%;
  }
}

.p-create__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .p-create__img {
    order: 2;
    width: 50%;
    flex: 0 0 auto;
  }
}

.p-create__body {
  width: 86.66%;
  flex: 0 0 auto;
}
@media screen and (min-width: 768px) {
  .p-create__body {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    right: -3.125rem;
  }
}

.p-create__title {
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
  letter-spacing: 0.15em;
}
@media screen and (min-width: 768px) {
  .p-create__title {
    font-size: 1.5rem;
    margin-bottom: 1.5625rem;
  }
}

.p-create__lead {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  color: #123269;
  letter-spacing: 0.2em;
}
@media screen and (min-width: 768px) {
  .p-create__lead {
    margin-bottom: 1.125rem;
    font-size: 1.875rem;
  }
}

.p-create__en {
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
  letter-spacing: 0.04em;
  color: #bababa;
}
@media screen and (min-width: 768px) {
  .p-create__en {
    margin-bottom: 1.875rem;
    font-size: 1.125rem;
  }
}

.p-create__text {
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.12em;
}

.p-eco {
  padding-top: 2.375rem;
  padding-bottom: 3.125rem;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .p-eco {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
}

@media screen and (min-width: 768px) {
  .p-eco__wrapper {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .p-eco__body {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.p-eco__title {
  max-width: 14.375rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-eco__title {
    max-width: 100%;
  }
}

.p-eco__lead {
  font-size: 1.25rem;
  line-height: 1.8;
  letter-spacing: 0.2em;
  color: #123269;
  margin-bottom: 1.5625rem;
}

.p-eco__img {
  margin-bottom: 1.875rem;
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 768px) {
  .p-eco__img {
    width: 50%;
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-eco__img img {
    width: 100%;
    object-fit: cover;
  }
}

.p-eco__text {
  line-height: 2;
  font-size: 0.75rem;
  letter-spacing: 0.06em;
}

.p-footer {
  padding: 1.875rem 0 1.25rem 0;
  background-color: #123269;
}
@media screen and (min-width: 768px) {
  .p-footer {
    padding: 3.875rem 0 1.875rem 0;
  }
}

.p-footer__wrapper {
  margin-bottom: 1.75rem;
}
@media screen and (min-width: 768px) {
  .p-footer__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 3.25rem;
  }
}

.p-footer__left {
  margin-bottom: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .p-footer__left {
    margin-bottom: 0;
  }
}

.p-footer__logo {
  display: block;
  margin-bottom: 1.25rem;
  width: 8.25rem;
}
@media screen and (min-width: 768px) {
  .p-footer__logo {
    margin-bottom: 1.4375rem;
  }
}

.p-footer__info {
  font-size: 0.625rem;
  line-height: 1.8;
  letter-spacing: 0.15em;
  margin-bottom: 1.25rem;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .p-footer__info {
    font-size: 0.75rem;
  }
}

.p-footer__info a {
  display: inline-block;
  background-color: #fff;
  color: #123269;
  padding: 0 0.1875rem;
  border-radius: 0.125rem;
  margin-left: 0.3125rem;
}

.p-footer__sns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.05em;
}

.p-footer__snslist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1.25rem;
}

.p-footer__snslist a:nth-child(1n+2) {
  margin-left: 0.625rem;
}

.p-footer__nav {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .p-footer__nav {
    justify-content: space-between;
    width: 44.27%;
  }
}

.p-footer__nav ul:nth-child(1),
.p-footer__nav ul:nth-child(2) {
  width: 8.75rem;
}
@media screen and (min-width: 768px) {
  .p-footer__nav ul:nth-child(1),
  .p-footer__nav ul:nth-child(2) {
    width: auto;
  }
}

.p-footer__nav a {
  color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.17em;
}

.p-footer__nav ul:nth-child(3) {
  width: 100%;
  display: flex;
  margin-top: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-footer__nav ul:nth-child(3) {
    margin-top: 0;
    width: auto;
  }
}

.p-footer__nav ul:nth-child(3) li:nth-child(1n+2) {
  margin: 0 0 0 1rem;
}

.p-footer__copy {
  font-size: 0.625rem;
  text-align: center;
  letter-spacing: 0.05em;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .p-footer__copy {
    font-size: 0.75rem;
  }
}

.p-form__name {
  padding: 1.875rem 0;
}
@media screen and (min-width: 768px) {
  .p-form__name {
    padding: 2.5rem 0;
    border-top: 1px solid #f0f0f0;
  }
}

@media screen and (min-width: 768px) {
  .p-form__address {
    padding: 2.5rem 0;
    border-top: 1px solid #f0f0f0;
  }
}

@media screen and (min-width: 768px) {
  .p-form__box {
    display: flex;
  }
}

.p-form__box:nth-child(1n+2) {
  margin-top: 1.875rem;
}

.p-form__box label {
  font-size: 0.8125rem;
  margin-bottom: 0.9375rem;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .p-form__box label {
    width: 17.1875rem;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-right: 1.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-form__box.company label {
    flex-direction: row;
  }
}

.p-form__box label span {
  padding: 0.25rem;
  margin-right: 0.5rem;
  display: inline-block;
  font-size: 0.625rem;
  letter-spacing: 0.25em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  background-color: #02436b;
}

@media screen and (min-width: 768px) {
  .p-form__inputbox {
    display: flex;
    width: 100%;
    gap: 2%;
  }
}

.p-form__inputbox input,
.p-form__inputbox textarea {
  width: 100%;
  display: block;
  border: 1px solid #d9d9d9;
  background-color: #f7f7f7;
  font-size: 0.875rem;
  padding: 0.625rem;
}

.p-form__inputbox input:nth-child(2n+2) {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-form__inputbox input:nth-child(2n+2) {
    margin-top: 0;
  }
}

.p-form__name input {
  width: 49%;
}

.p-form__privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.p-form__privacy input {
  margin-right: 0.5rem;
}

.p-form__privacy a {
  color: #bfbfbf;
  text-decoration: underline;
}

.p-form__submit {
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .p-form__submit {
    text-align: center;
  }
}

.p-form__submit input {
  width: 100%;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  height: 3.4375rem;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.24em;
}
@media screen and (min-width: 768px) {
  .p-form__submit input {
    width: 15rem;
  }
}

.p-form__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #123269;
  z-index: 5000;
  display: none;
}

.p-form__modal-textbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.p-fv {
  position: relative;
}

.p-fv__img {
  height: 100vh;
}
@media screen and (min-width: 768px) {
  .p-fv__img {
    height: auto;
  }
}

.p-fv__img picture img {
  height: 100vh;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-fv__img picture img {
    height: auto;
  }
}

.p-fv__body {
  width: 86.66%;
  position: absolute;
  left: 6.66%;
  bottom: 18.71%;
  color: #fff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  font-family: "vdl-v7mincho", sans-serif;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .p-fv__body {
    width: 100%;
    left: 8.05%;
    bottom: 16.92%;
  }
}

.p-fv__title {
  letter-spacing: 0.15em;
  font-size: 1.5625rem;
  margin-bottom: 1.25rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-fv__title {
    font-size: 2.25rem;
    margin-bottom: 1.875rem;
    letter-spacing: 0.19em;
  }
}

.p-fv__text {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  line-height: 2;
}
@media screen and (min-width: 768px) {
  .p-fv__text {
    font-size: 0.9375rem;
  }
}

.p-greeting__title img {
  max-width: 18rem;
}

.p-greeting__card {
  margin-top: 3.125rem;
}

.p-greeting__wrapper {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-greeting__wrapper {
    display: flex;
  }
}

.p-greeting__col {
  width: 41.66666667%;
  flex: 0 0 auto;
  padding: 0 3.125rem;
}

.p-greeting__col-card {
  margin-top: 8.25rem;
}

.p-greeting__imgarea {
  flex: 0 0 auto;
  width: 58.33333333%;
  padding-left: 3.125rem;
}

.p-greeting__img {
  position: sticky;
  top: 9.375rem;
}

.p-guideline {
  padding: 5.375rem 0 3.25rem 0;
}
@media screen and (min-width: 768px) {
  .p-guideline {
    padding: 6.25rem 0 8.75rem 0;
  }
}

.p-guideline img {
  margin: 0 auto;
}

.p-header {
  background-color: #fff;
  padding: 1.25rem 1.25rem 1.25rem 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
@media screen and (min-width: 768px) {
  .p-header {
    background-color: transparent;
    position: absolute;
    top: 1.25rem;
    left: 0;
    width: 100%;
    padding: 0 3.125rem;
  }
}

.p-header.page {
  color: #212529;
}

.p-header__title img {
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.16));
}

.p-header__toggle {
  position: relative;
  width: 1.5625rem;
  height: 1rem;
  cursor: pointer;
  z-index: 500;
}
@media screen and (min-width: 768px) {
  .p-header__toggle {
    display: none;
  }
}

.p-header__toggle span {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: #000;
  transition: 0.5s;
}

.p-header__toggle.show span {
  background-color: #fff;
}

.p-header__toggle span:nth-child(1) {
  top: 0;
}

.p-header__toggle span:nth-child(2) {
  top: 0.4375rem;
}

.p-header__toggle span:nth-child(3) {
  bottom: 0;
}

.p-header__toggle.show span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.p-header__toggle.show span:nth-child(2) {
  display: none;
}

.p-header__toggle.show span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.p-header__nav {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-header__nav {
    display: flex;
    align-items: center;
  }
}

.p-header__list {
  display: flex;
  color: #fff;
}

.p-header__list.page {
  color: #212529;
}

.p-header__item {
  font-size: 0.875rem;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  letter-spacing: 0.19em;
  font-family: "vdl-v7mincho", sans-serif;
}

.p-header__link {
  width: 9.0625rem;
  height: 2.0625rem;
  font-size: 0.75rem;
  letter-spacing: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #123269;
  margin-left: 1.875rem;
}

.p-header__link span {
  padding-right: 2.0625rem;
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/common/nav-arrow.svg) no-repeat;
  background-position: right 0 center;
}

.p-header__menu {
  display: none;
}

.p-header__menu.show {
  display: block;
}

.p-history {
  background-color: #eaeaea;
}

.p-history__img {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.p-history__body {
  padding: 1.875rem 6.67%;
  position: relative;
  background-color: #123269;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 768px) {
  .p-history__body {
    padding: 3.625rem 4.6875rem;
    width: 54.83%;
    margin: -5.5rem 0 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: calc(50% - 50vw);
  }
}

.p-history__title {
  margin-bottom: 1.5625rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 0.15em;
  font-weight: 400;
}

.p-history__title span {
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .p-history__btn {
    margin-left: 5.625rem;
  }
}

.p-interview {
  padding-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-interview {
    padding-bottom: 6.25rem;
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  .p-interview::before {
    content: "";
    display: block;
    height: 80%;
    width: 15%;
    background-color: #123269;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.p-interview__title {
  width: 16.75rem;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .p-interview__title {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-interview__cards {
    display: flex;
  }
}

.p-interview__card:not(:last-of-type) {
  margin-bottom: 1.875rem;
}

@media screen and (min-width: 768px) {
  .p-interview__card:nth-of-type(3n-1) {
    margin-top: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-interview__card:nth-of-type(3n) {
    margin-top: 5rem;
  }
}

.p-lead__lead {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.16em;
  text-align: center;
  color: #123269;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-lead__lead {
    font-size: 1.625rem;
    line-height: 2;
    margin-bottom: 5rem;
  }
}

.p-lead__lead img {
  width: 17.625rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-lead__lead img {
    width: 28.5625rem;
  }
}

.p-lead__text {
  font-size: 0.75rem;
  line-height: 2.5;
  letter-spacing: 0.06em;
  text-align: center;
  margin-top: -1.875rem;
}
@media screen and (min-width: 768px) {
  .p-lead__text {
    font-size: 0.875rem;
    margin-top: -3.75rem;
  }
}

.p-menu {
  background-color: #123269;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #fff;
}

.p-menu__wrapper {
  display: flex;
  flex-direction: column;
}

.p-menu__title {
  margin: 1.25rem 0 4.875rem 1.5625rem;
}

.p-menu__title img {
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.16));
}

.p-menu__list {
  margin-bottom: 2.625rem;
}

.p-menu__item a {
  letter-spacing: 0.16em;
}

.p-menu__item a::before {
  content: "-";
}

.p-menu__item + .p-menu__item {
  margin-top: 1rem;
}

.p-menu__sns {
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  margin-bottom: 2.5rem;
}

.p-menu__sns a {
  display: inline-block;
  margin-top: 0.625rem;
}

.p-menu__sns a:nth-of-type(1n+2) {
  margin-left: 0.625rem;
}

.p-menu__sns a img {
  width: 2.5rem;
}

.p-menu__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  font-size: 0.875rem;
  letter-spacing: 0.24em;
  width: 100%;
  height: 3.25rem;
}

.p-menu__btn span {
  display: inline-flex;
  align-items: center;
}

.p-menu__btn span img {
  margin-right: 0.625rem;
}

.p-menu__btn + .p-menu__btn {
  margin-top: 1.125rem;
}

.p-menu__bottom {
  margin-top: 1.5625rem;
  font-size: 0.8125rem;
  letter-spacing: 0.15em;
}

.p-news-list {
  padding-top: 2.375rem;
}
@media screen and (min-width: 768px) {
  .p-news-list {
    padding-top: 4.375rem;
    padding-bottom: 3.75rem;
  }
}

@media screen and (min-width: 768px) {
  .p-news-list__wrapper {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .p-news-list__main {
    width: 66%;
    padding: 0 2.375rem;
  }
}

.p-news-list__main.works {
  background-color: #fff;
  padding: 2.5rem 0.75rem;
  margin-top: 3.75rem;
}
@media screen and (min-width: 768px) {
  .p-news-list__main.works {
    padding: 2.5rem 2.375rem;
  }
}

.p-news-list__title {
  max-width: 11.375rem;
}
@media screen and (min-width: 768px) {
  .p-news-list__title {
    max-width: 100%;
    margin-bottom: 5.3125rem;
  }
}

.p-news-list__title.contact {
  max-width: 20.3125rem;
}
@media screen and (min-width: 768px) {
  .p-news-list__title.contact {
    max-width: 100%;
  }
}

.p-news-list__title img {
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-news-list__title img {
    margin-bottom: 1.875rem;
  }
}

.p-news-list__title span {
  display: block;
  margin-top: 1.25rem;
  font-size: 1.5625rem;
  line-height: 1;
  letter-spacing: 0.16em;
  font-weight: 400;
  font-family: "vdl-v7mincho", sans-serif;
}
@media screen and (min-width: 768px) {
  .p-news-list__title span {
    font-family: "noto-sans-cjk-jp", YuGothic, "Yu Gothic", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
  }
}

.p-news-list__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.875rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-news-list__cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-news-list__card {
  border-bottom: 1px solid #e0e0e0;
  padding: 2.5rem 0 1.875rem 0;
}

.p-news-list__pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.p-news-list__pagenation .page {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "vdl-v7mincho", sans-serif;
  border: 1px solid #123269;
  font-size: 0.9375rem;
  padding: 0.5rem 0.625rem;
  background-color: #123269;
  color: #fff;
}

.p-news-list__pagenation .larger {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "vdl-v7mincho", sans-serif;
  color: #123269;
  border: 1px solid #123269;
  font-size: 0.9375rem;
  padding: 0.5rem 0.625rem;
}

.p-news-list__pagenation .next {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "vdl-v7mincho", sans-serif;
  color: #123269;
  border: 1px solid #123269;
  font-size: 0.9375rem;
  padding: 0.5rem 0.625rem;
}

.p-news-list__aside {
  margin-top: 3.75rem;
}
@media screen and (min-width: 768px) {
  .p-news-list__aside {
    width: 33%;
    padding: 0 2.375rem;
    margin-top: 2.5rem;
  }
}

.p-news {
  padding: 5.4375rem 0 4.375rem 0;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .p-news {
    padding: 3rem 0 7.5rem 0;
  }
}

.p-news__title {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  position: relative;
}

.p-news__title img {
  width: 11.125rem;
  height: 3.3125rem;
}
@media screen and (min-width: 768px) {
  .p-news__title img {
    width: 21.6875rem;
    height: 6.4375rem;
  }
}

.p-news__more {
  font-size: 0.75rem;
  position: absolute;
  letter-spacing: 0.24em;
  border-bottom: 1px solid #212529;
  padding-bottom: 0.25rem;
  right: 0;
  bottom: 0;
}

.p-news__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 768px) {
  .p-news__cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

.p-news__card {
  margin-top: 1.25rem;
}

.p-order {
  padding-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-order {
    padding-top: 6.25rem;
  }
}

.p-order__title {
  max-width: 14.375rem;
}
@media screen and (min-width: 768px) {
  .p-order__title {
    max-width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-order__title img {
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .p-order__table {
    width: 100%;
    max-width: 66.625rem;
    margin: 0 auto;
  }
}

.p-order__table tr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 1.25rem 0;
}
@media screen and (min-width: 768px) {
  .p-order__table tr {
    border-top: 1px solid #d9d9d9;
    width: 100%;
  }
}

.p-order__table th {
  width: 100%;
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-order__table th {
    width: 12.8125rem;
    text-align: center;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-order__table td {
    padding: 1.25rem 0;
    width: 12.8125rem;
    text-align: center;
  }
}

.p-order__table td:first-of-type {
  width: auto;
  font-size: 0.75rem;
  font-weight: 500;
  padding-right: 1.5em;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.12em;
}
@media screen and (min-width: 768px) {
  .p-order__table td:first-of-type {
    width: 12.8125rem;
  }
}

.p-order__table td:nth-of-type(2) {
  color: #212529;
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: 0.06em;
}
@media screen and (min-width: 768px) {
  .p-order__table td:nth-of-type(2) {
    text-align: left;
  }
}

.p-order__table td a {
  color: #c7c7c7;
  text-decoration: underline;
}

.p-outline {
  padding: 5rem 0;
  background-color: #eaeaea;
}

.p-outline__title {
  max-width: 14.375rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-outline__title {
    max-width: 100%;
  }
}

.p-outline__table {
  border-collapse: collapse;
}
@media screen and (min-width: 768px) {
  .p-outline__table {
    width: 100%;
  }
}

.p-outline__table th {
  width: 9.375rem;
  color: #123269;
  padding: 1.875rem 1.25rem;
  border-width: 0.125rem 0;
  border-style: solid;
  border-color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.12em;
  line-height: 2;
  text-align: left;
}

.p-outline__table td {
  padding: 1.875rem 1.25rem;
  border-width: 0.125rem 0;
  border-style: solid;
  border-color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.12em;
  line-height: 2;
}

.p-outline__table td a {
  color: #0d6efd;
  text-decoration: underline;
}

.p-page-fv {
  margin-top: 6.25rem;
  margin-bottom: 2.5rem;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .p-page-fv {
    margin-top: 0;
    padding-left: 9.375rem;
    margin-bottom: 6.25rem;
  }
}

.p-page-fv__title {
  position: absolute;
  top: 50%;
  left: 3.125rem;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1.5625rem;
  line-height: 1;
  letter-spacing: 0.16em;
  font-weight: 400;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .p-page-fv__title {
    position: static;
    transform: translateY(0);
    margin: 5.3125rem 0;
    padding: 0 1.875rem;
    color: #212529;
  }
}

.p-page-fv__title img {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-page-fv__title img {
    display: block;
    margin-bottom: 1.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-page-fv__img {
    overflow: hidden;
  }
}

.p-page-fv__img img {
  transform: translate3d(0px, -26px, 0px) scale(1.3);
  will-change: transform;
}
@media screen and (min-width: 768px) {
  .p-page-fv__img img {
    transform: translate3d(0px, -14px, 0px) scale(1.3);
    will-change: transform;
    width: 100%;
  }
}

.p-page-pan {
  display: none;
  margin-top: 6.25rem;
  padding: 1.875rem 3.75rem 0;
}
@media screen and (min-width: 768px) {
  .p-page-pan {
    display: block;
  }
}

.p-page-pan span {
  color: #a8a8a8;
  font-size: 0.6875rem;
  line-height: 1;
  letter-spacing: 0.16em;
}

.p-philosophy {
  padding: 7.5rem 0 1.5rem 0;
}

.p-philosophy__title {
  text-align: center;
}

.p-philosophy__title img {
  margin: 0 auto;
}

.p-philosophy__lead {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.16em;
  margin: 1.875rem 0 1.25rem 0;
  color: #123269;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-philosophy__lead {
    font-size: 1.625rem;
    line-height: 2;
  }
}

.p-philosophy__text {
  font-size: 0.75rem;
  line-height: 2.5;
  letter-spacing: 0.06em;
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-philosophy__text {
    font-size: 0.875rem;
    text-align: center;
  }
}

.p-quality {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-quality {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-quality__wrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
  }
}

.p-quality__title {
  max-width: 13.1875rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-quality__title {
    max-width: 100%;
  }
}

.p-quality__img {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-quality__img {
    margin-bottom: 0;
    width: 50%;
  }
}

.p-quality__img img:first-of-type {
  margin-left: auto;
  box-shadow: 0 0 0.625rem rgba(33, 37, 41, 0.15);
}
@media screen and (min-width: 768px) {
  .p-quality__img img:first-of-type {
    max-width: 28.25rem;
  }
}

.p-quality__img img:nth-of-type(2) {
  width: 61%;
  max-width: 17.5rem;
  margin-top: -5.625rem;
  margin-right: -1.5625rem;
  margin-left: auto;
  box-shadow: 0 0 0.625rem rgba(33, 37, 41, 0.15);
}
@media screen and (min-width: 768px) {
  .p-quality__img img:nth-of-type(2) {
    margin-left: -1.875rem;
    margin-right: auto;
  }
}

.p-quality__body {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-quality__body {
    width: 50%;
    margin-top: 0;
  }
}

.p-quality__lead {
  font-size: 0.9375rem;
  line-height: 1.7;
  letter-spacing: 0.06em;
  margin-bottom: 0.625rem;
  color: #123269;
}

.p-quality__text {
  font-size: 0.75rem;
  line-height: 2;
  letter-spacing: 0.06em;
}
@media screen and (min-width: 768px) {
  .p-quality__text {
    font-size: 0.8125rem;
  }
}

.p-quality__box {
  background-color: #f8f8f8;
  padding: 0.9375rem 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-quality__box {
    margin-top: 1.5625rem;
  }
}

.p-quality__box div {
  font-size: 0.75rem;
  display: flex;
  line-height: 2;
  letter-spacing: 0.06em;
}
@media screen and (min-width: 768px) {
  .p-quality__box div {
    font-size: 0.8125rem;
  }
}

.p-quality__box dt {
  width: 5.625rem;
  font-weight: 700;
}

.p-quality__box dd {
  margin-bottom: 0.5rem;
}

.p-quality__text a {
  color: #b4b4b4;
  text-decoration: underline;
}

.p-quality__button {
  margin-top: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .p-quality__button {
    display: none;
  }
}

.p-recruit {
  background-color: #123269;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .p-recruit {
    background: linear-gradient(180deg, #eaeaea 5%, #123269 5%, #123269 95%, #eaeaea 95%);
  }
}

@media screen and (min-width: 768px) {
  .p-recruit__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 85.375rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .p-recruit__img {
    width: 66.47%;
    margin-left: -6.88%;
  }
}

.p-recruit__body {
  padding: 1.875rem 6.67%;
}
@media screen and (min-width: 768px) {
  .p-recruit__body {
    width: 33.53%;
    margin: 0;
    padding: 0;
  }
}

.p-recruit__title {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-recruit__title {
    margin-bottom: 0.5rem;
  }
}

.p-recruit__title-img {
  width: 14.75rem;
}
@media screen and (min-width: 768px) {
  .p-recruit__title-img {
    width: 100%;
  }
}

.p-recruit__subtitle {
  max-width: 19.375rem;
  width: 100%;
  margin-top: 0.5rem;
}

.p-recruit__text {
  font-size: 0.75rem;
  margin-bottom: 1.25rem;
  letter-spacing: 0.05em;
  line-height: 2.2;
}
@media screen and (min-width: 768px) {
  .p-recruit__text {
    font-size: 0.8125rem;
    margin-bottom: 1.875rem;
  }
}

.p-requirements {
  padding: 3.125rem 0;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .p-requirements {
    padding: 6.25rem 0;
  }
}

.p-requirements__title {
  margin-bottom: 0.9375rem;
  width: 25rem;
}
@media screen and (min-width: 768px) {
  .p-requirements__title {
    margin-bottom: 1.875rem;
    width: 100%;
  }
}

.p-requirements__cards {
  max-width: 33.75rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .p-requirements__cards {
    max-width: 100%;
  }
}

.p-safety {
  padding-bottom: 2.5rem;
}

.p-safety__title {
  max-width: 11.0625rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-safety__title {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-safety__wrapper {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .p-safety__img {
    width: 50%;
  }
}

.p-safety__img img {
  width: 100%;
  max-width: 31.5rem;
  box-shadow: 0 0 0.625rem rgba(33, 37, 41, 0.15);
}

.p-safety__body {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-safety__body {
    width: 50%;
    margin-left: 3.75rem;
    margin-top: 0;
  }
}

.p-sdgs-list {
  padding: 3.125rem 0;
}
@media screen and (min-width: 768px) {
  .p-sdgs-list {
    padding: 6.25rem 0;
  }
}

@media screen and (min-width: 768px) {
  .p-sdgs-list__item {
    max-width: 66.625rem;
    margin: 0 auto;
    position: relative;
  }
}

.p-sdgs-list__item:not(:last-of-type) {
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-sdgs-list__item:not(:last-of-type) {
    margin-bottom: 3.75rem;
  }
}

.p-sdgs {
  background-color: #123269;
  color: #fff;
}

.p-sdgs__wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .p-sdgs__img {
    width: 66.6666667%;
    flex: 0 0 auto;
  }
}

.p-sdgs__body {
  padding: 2.5rem 6.67vw;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-sdgs__body {
    width: 33.33333333%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
  }
}

.p-sdgs__title {
  font-size: 1.5625rem;
  letter-spacing: 0.2em;
  margin-bottom: 0.9375rem;
}

.p-sdgs__text {
  margin-bottom: 2.5rem;
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.2em;
}

.p-sdgs__btn {
  width: 100%;
}

.p-spirit {
  padding: 1.5rem 0;
}

.p-spirit__title {
  text-align: center;
  color: #123269;
}

@media screen and (min-width: 768px) {
  .p-spirit__title img {
    margin: 0 auto;
  }
}

.p-spirit__title span {
  font-size: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-spirit__title span {
    font-size: 1.625rem;
  }
}

.p-spirit__text {
  font-size: 0.75rem;
  line-height: 2.5;
  letter-spacing: 0.06em;
}
@media screen and (min-width: 768px) {
  .p-spirit__text {
    text-align: center;
    font-size: 0.875rem;
  }
}

.p-spirit__text:first-of-type {
  margin-top: 1.25rem;
}

.p-spirit__text:nth-of-type(1n+2) {
  margin-top: 0.625rem;
}

.p-work {
  padding: 2.5rem 0;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .p-work {
    padding: 5rem 0;
  }
}

@media screen and (min-width: 768px) {
  .p-work__cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.p-work__card {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-work__card {
    padding: 0 0.8125rem;
    width: calc(100%/4 - 0.8125rem/4);
    margin-top: 0;
  }
}

.p-work__card:not(:last-of-type) {
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-work__card:not(:last-of-type) {
    margin-bottom: 0;
  }
}

.p-work__card:nth-child(n+5) {
  margin-top: 1.875rem;
}

.p-works__item {
  margin-bottom: 1.875rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #e0e0e0;
}

.p-works__item a {
  display: block;
}
@media screen and (min-width: 768px) {
  .p-works__item a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.p-works__item h3 {
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
  letter-spacing: 0.06em;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .p-works__item h3 {
    font-size: 0.875rem;
    margin-right: 1.5625rem;
    letter-spacing: 0.3em;
  }
}

.p-works__cate {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.p-works__cate span {
  color: #123269;
  border: 1px solid #123269;
  padding: 1px 0.625rem;
  font-size: 0.6875rem;
  letter-spacing: 0.05em;
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}