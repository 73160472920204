@use "global" as *;

.p-sdgs-list {
  padding: rem(50) 0;

  @include mq(md) {
    padding: rem(100) 0;
  }
}

.p-sdgs-list__item {
  @include mq(md) {
    max-width: rem(1066);
    margin: 0 auto;
    position: relative;
  }
}

.p-sdgs-list__item:not(:last-of-type) {
  margin-bottom: rem(30);

  @include mq(md) {
    margin-bottom: rem(60);
  }
}