@use "global" as *;

.c-greeting-card__img {
  width: 86.66%;
  margin-bottom: rem(40);
  margin-left: calc(50% - 50vw);
}

.c-greeting-card__img.right {
  margin-left: auto;
  margin-right: calc(50% - 50vw);
}

.c-greeting-card__title {
  font-size: rem(18);
  letter-spacing: 0.2em;
  color: #123269;
  margin-bottom: rem(30);
  line-height: 2;
  font-weight: 500;
}

.c-greeting-card__text {
  font-size: rem(13);
  letter-spacing: 0.11em;
  line-height: 2.2;
}

.c-greeting-card__sign {
  margin-top: rem(30);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: rem(13);
  letter-spacing: 0.11em;
}

.c-greeting-card__sign span {
  margin-right: rem(8);
}

.c-greeting-card__sign img {
  width: rem(138);
}