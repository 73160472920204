@use "global" as *;

.p-chronology {
  overflow: hidden;

  @include mq(md) {
    overflow: visible;
    padding-bottom: rem(100);
  }
}

.p-chronology__swiper {
  position: relative;
}

.swiper-control {
  position: absolute;
  top: 120vw;
  right: 3%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: rem(90);
  height: rem(40);
}

.p-chronology__swiper-prev {
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/page/our_history/slider-prev.svg) no-repeat;
  background-position: center;
}

.p-chronology__swiper-next {
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/page/our_history/slider-next.svg) no-repeat;
  background-position: center;
  margin-left: rem(10);
}

.p-chronology__title {
  font-size: rem(22);
  letter-spacing: 0.16em;
  line-height: 2;
  margin-bottom: rem(20);
}

.p-chronology__wrapper {
  display: flex;
  height: 100%;
}

.p-chronology__body {
  width: 50%;
}

.p-chronology__imgarea {
  width: 50%;
  flex: 0 0 auto;
  position: relative;
}

.p-chronology__img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.p-chronology__img img {
  position: sticky;
  top: rem(100);
  left: 0;
}

.p-chronology__img.show:nth-child(1) {
  z-index: -2;
}