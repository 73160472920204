@use "global" as *;

.p-achievement {
  padding: rem(80) 0 0;
  position: relative;

  @include mq(md) {
    padding: rem(80) 0;
  }
}

.p-achievement::before {
  content: "";
  display: block;
  width: 15%;
  height: 100%;
  background: #123269;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.p-achievement__wrapper {
  @include mq(md) {
    display: flex;
    align-items: flex-end;
  }
}

.p-achievement__img {
  max-width: rem(540);
  margin-bottom: rem(20);
  margin-right: calc(50% - 50vw);

  @include mq(md) {
    width: 45%;
    margin: 0;
    flex: 0 0 auto;
    padding: 0 rem(44);
  }
}

.p-achievement__img img {
  max-width: 100%;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%)
}

.p-achievement__body {
  background-color: $white;
  width: 100vw;
  padding: rem(30) rem(25) rem(40) rem(25);
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  @include mq(md) {
    width: 45%;
    margin: 0 0 0 auto;
    padding-left: rem(10);
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    flex: 0 0 auto;
  }
}

.p-achievement__title {
  font-size: rem(20);
  margin-bottom: rem(15);
  line-height: 1;
  letter-spacing: 0.16em;
  font-weight: 500;

  @include mq(md) {
    font-size: rem(22);
    color: #123269;
    margin-bottom: rem(25);
  }
}

.p-achievement__text {
  font-size: rem(12);
  margin-bottom: rem(30);

  @include mq(md) {
    font-size: rem(13);
    line-height: 2;
    letter-spacing: 0.12em;
    margin-bottom: rem(40);
  }
}

.p-achievement__btn:not(:last-child) {
  margin-bottom: rem(20);
}