@use "global" as *;

.c-news-card {
  padding-left: rem(10);
  padding-right: rem(10);

  @include mq(md) {
    padding-left: rem(15);
    padding-right: rem(15);
  }
}

.c-news-card a {
  display: block;
}

.c-news-card__img {
  aspect-ratio: 4/3;
  // height: rem(152);
  margin-bottom: rem(14);

  @include mq(md) {
    // height: rem(210);
    margin-bottom: rem(30);
  }
}

.c-news-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-news-card__category {
  font-size: rem(10);
  letter-spacing: 0.12em;
  padding: rem(2) rem(5);
  border: 1px solid #123269;
  display: inline-block;
}

.c-news-card__title {
  font-size: rem(11);
  margin-top: rem(20);
  margin-bottom: rem(10);
  letter-spacing: 0.05em;
  line-height: 1.71;

  @include mq(md) {
    font-size: rem(14);
    margin-top: rem(25);
    margin-bottom: rem(15);
  }
}

.c-news-card__time {
  display: block;
  font-family: $font-sub;
  font-size: rem(12);
  letter-spacing: 0.05em;
  color: #123269;
}