@use "global" as *;

.p-able {
  padding-bottom: rem(50);
  background-color: #eaeaea;

  @include mq(md) {
    padding-bottom: rem(100);
  }
}

.p-able__wrapper {
  padding-left: rem(25);

  @include mq(md) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
  }
}

.p-able__img {
  margin-bottom: rem(30);

  @include mq(md) {
    width: 50%;
    flex: 0 0 auto;
  }
}

.p-able__body {
  max-width: rem(540);
  width: calc(100% - #{rem(25)});

  @include mq(md) {
    max-width: rem(483);
    padding-left: rem(50);
    margin: 0 auto;
    width: 41.66666667%;
    flex: 0 0 auto;
  }
}

.p-able__title {
  margin-bottom: rem(10);
  color: #123269;
  font-weight: bold;
  font-size: rem(20);
  line-height: 2;
  letter-spacing: 0.06em;
}

.p-able__text {
  margin-bottom: 1em;
  line-height: 2.3;
  letter-spacing: 0.06em;
  font-size: rem(12);
}

.p-able__button {
  max-width: calc(100% - #{rem(23)});
}