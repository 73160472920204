@use "global" as *;

.p-form__name {
  padding: rem(30) 0;

  @include mq(md) {
    padding: rem(40) 0;
    border-top: 1px solid #f0f0f0;
  }
}

.p-form__address {
  @include mq(md) {
    padding: rem(40) 0;
    border-top: 1px solid #f0f0f0;
  }
}

.p-form__box {
  @include mq(md) {
    display: flex;
  }
}

.p-form__box:nth-child(1n+2) {
  margin-top: rem(30);
}

.p-form__box label {
  font-size: rem(13);
  margin-bottom: rem(15);
  display: flex;
  align-items: center;

  @include mq(md) {
    width: rem(275);
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-right: rem(30);
  }
}

.p-form__box.company label {
  @include mq(md) {
    flex-direction: row;
  }
}

.p-form__box label span {
  padding: rem(4);
  margin-right: rem(8);
  display: inline-block;
  font-size: rem(10);
  letter-spacing: 0.25em;
  font-weight: 500;
  line-height: 1;
  color: $white;
  background-color: #02436b;
}

.p-form__inputbox {
  @include mq(md) {
    display: flex;
    width: 100%;
    gap: 2%;
  }
}

.p-form__inputbox input,
.p-form__inputbox textarea {
  width: 100%;
  display: block;
  border: 1px solid #d9d9d9;
  background-color: #f7f7f7;
  font-size: rem(14);
  padding: rem(10);
}

.p-form__inputbox input:nth-child(2n+2) {
  margin-top: rem(15);

  @include mq(md) {
    margin-top: 0;
  }
}

.p-form__name input {
  width: 49%;
}

.p-form__privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(13);
  margin-top: rem(30);
  margin-bottom: rem(30);
}

.p-form__privacy input {
  margin-right: rem(8);
}

.p-form__privacy a {
  color: #bfbfbf;
  text-decoration: underline;
}

.p-form__submit {
  cursor: pointer;

  @include mq(md) {
    text-align: center;
  }
}

.p-form__submit input {
  width: 100%;
  background-color: $white;
  border: 1px solid #b3b3b3;
  height: rem(55);
  font-size: rem(12);
  font-weight: 300;
  letter-spacing: 0.24em;

  @include mq(md) {
    width: rem(240);
  }
}

.p-form__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #123269;
  z-index: 5000;
  display: none;
}

.p-form__modal-textbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $white;
}