@use 'global' as *;

.p-footer {
  padding: rem(30) 0 rem(20) 0;
  background-color: #123269;

  @include mq(md) {
    padding: rem(62) 0 rem(30) 0;
  }
}

.p-footer__wrapper {
  margin-bottom: rem(28);

  @include mq(md) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: rem(52);
  }
}

.p-footer__left {
  margin-bottom: rem(25);

  @include mq(md) {
    margin-bottom: 0;
  }
}

.p-footer__logo {
  display: block;
  margin-bottom: rem(20);
  width: rem(132);

  @include mq(md) {
    margin-bottom: rem(23);
  }
}

.p-footer__info {
  font-size: rem(10);
  line-height: 1.8;
  letter-spacing: 0.15em;
  margin-bottom: rem(20);
  color: $white;

  @include mq(md) {
    font-size: rem(12);
  }
}

.p-footer__info a {
  display: inline-block;
  background-color: $white;
  color: #123269;
  padding: 0 rem(3);
  border-radius: rem(2);
  margin-left: rem(5);
}

.p-footer__sns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $white;
  font-size: rem(13);
  letter-spacing: 0.05em;
}

.p-footer__snslist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: rem(20);
}

.p-footer__snslist a:nth-child(1n+2) {
  margin-left: rem(10);
}

.p-footer__nav {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: auto;

  @include mq(md) {
    justify-content: space-between;
    width: 44.27%;
  }
}

.p-footer__nav ul:nth-child(1),
.p-footer__nav ul:nth-child(2) {
  width: rem(140);

  @include mq(md) {
    width: auto;
  }
}

.p-footer__nav a {
  color: $white;
  font-size: rem(13);
  letter-spacing: 0.17em;
}

.p-footer__nav ul:nth-child(3) {
  width: 100%;
  display: flex;
  margin-top: rem(10);

  @include mq(md) {
    margin-top: 0;
    width: auto;
  }
}

.p-footer__nav ul:nth-child(3) li:nth-child(1n+2) {
  margin: 0 0 0 rem(16);
}

.p-footer__copy {
  font-size: rem(10);
  text-align: center;
  letter-spacing: 0.05em;
  color: $white;

  @include mq(md) {
    font-size: rem(12);
  }
}