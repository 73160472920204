@use "global" as *;

.p-spirit {
  padding: rem(24) 0;
}

.p-spirit__title {
  text-align: center;
  color: #123269;
}

.p-spirit__title img {
  @include mq(md) {
    margin: 0 auto;
  }
}


.p-spirit__title span {
  font-size: rem(20);

  @include mq(md) {
    font-size: rem(26);
  }
}

.p-spirit__text {
  font-size: rem(12);
  line-height: 2.5;
  letter-spacing: 0.06em;

  @include mq(md) {
    text-align: center;
    font-size: rem(14);
  }
}

.p-spirit__text:first-of-type {
  margin-top: rem(20);
}

.p-spirit__text:nth-of-type(1n+2) {
  margin-top: rem(10);
}