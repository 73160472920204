@use "global" as *;

.c-sdgs-card {
  position: relative;
}

.c-sdgs-card__title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(19);
  color: $white;
  background-color: #123269;
  line-height: 1.5;
  padding: rem(20);
  letter-spacing: 0.16em;

  @include mq(md) {
    position: absolute;
    top: rem(30);
    left: rem(20);
    line-height: 2;
    padding: rem(5) rem(45);
    background-color: transparent;
  }
}

.c-sdgs-card__title::before {
  @include mq(md) {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #123269;
    transform: skew(-25deg, 0);
    z-index: -1;
  }
}

.c-sdgs-card__wrapper {
  border: 1px solid #b3b3b3;
  padding: rem(25) rem(20) rem(20);

  @include mq(md) {
    padding: rem(100) rem(45) rem(40);
    display: flex;
    align-items: flex-end;
  }
}

.c-sdgs-card__body {
  @include mq(md) {
    width: 66.66666667%;
    flex: 0 0 auto;
    padding-right: rem(20);
  }
}

.c-sdgs-card__lead {
  font-size: rem(15);
  margin-left: -0.5em;
  margin-bottom: rem(10);
  line-height: 2;
  letter-spacing: 0.06em;
  font-weight: bold;
}

.c-sdgs-card__text {
  font-size: rem(12);
  line-height: 2;
  letter-spacing: 0.06em;
  max-width: rem(600);
  margin-bottom: rem(25);
}

.c-sdgs-card__list {
  margin-bottom: rem(25);

  @include mq(md) {
    display: flex;
  }
}

.c-sdgs-card__list ul:first-of-type {
  width: 45%;
  padding-right: rem(20);
}

.c-sdgs-card__list li {
  font-size: rem(12);
  line-height: 2.3;
  letter-spacing: 0.06em;
  padding-left: 1em;
  text-indent: -1em;
  font-weight: bold;
}

.c-sdgs-card__list li::before {
  content: "■";
  color: rgb(18, 50, 105);
}

.c-sdgs-card__img {
  width: 100%;
  max-width: rem(285);
  margin: 0 auto;

  @include mq(md) {
    width: 33.33333333%;
    flex: 0 0 auto;
  }
}