@use "global" as *;

.p-requirements {
  padding: rem(50) 0;
  background-color: #eaeaea;

  @include mq(md) {
    padding: rem(100) 0;
  }
}

.p-requirements__title {
  margin-bottom: rem(15);
  width: rem(400);

  @include mq(md) {
    margin-bottom: rem(30);
    width: 100%;
  }
}

.p-requirements__cards {
  max-width: rem(540);
  margin-left: auto;
  margin-right: auto;

  @include mq(md) {
    max-width: 100%;
  }
}