@use "global" as *;

.p-work {
  padding: rem(40) 0;
  background-color: #eaeaea;

  @include mq(md) {
    padding: rem(80) 0;
  }
}

.p-work__cards {
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.p-work__card {
  margin-top: rem(30);

  @include mq(md) {
    padding: 0 rem(13);
    width: calc(100%/4 - #{rem(13)}/4);
    margin-top: 0;
  }
}

.p-work__card:not(:last-of-type) {
  margin-bottom: rem(30);

  @include mq(md) {
    margin-bottom: 0;
  }
}

.p-work__card:nth-child(n+5) {
  margin-top: rem(30);
}