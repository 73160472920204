@use "global" as *;

.p-business {
  padding: rem(50) 0;

  @include mq(md) {
    padding: rem(120) 0;
    background-image: linear-gradient(90deg, transparent 0%, #123269 0%, #123269 10%, transparent 10%), repeating-linear-gradient(90deg, transparent, transparent 190px, rgba(226, 226, 226, 0.4) 190px, rgba(226, 226, 226, 0.4) 191px), repeating-linear-gradient(0deg, transparent, transparent 190px, rgba(226, 226, 226, 0.4) 190px, rgba(226, 226, 226, 0.4) 191px);
  }
}

.p-business__wrapper {
  @include mq(md) {
    display: flex;
    align-items: center;
  }
}

.p-business__imgarea {
  position: relative;
  width: 84.3%;
  margin-top: rem(30);
  padding: 0 rem(12);

  @include mq(md) {
    width: 43.84%;
    flex: 1 0 0%;
    margin-top: 0;
    padding: 0 rem(44);
  }
}

.p-business__img02 {
  width: 54.19%;
  position: absolute;
  bottom: -8%;
  right: -10%;

  @include mq(md) {
    right: 0;
  }
}

.p-business__imgarea img {
  box-shadow: rem(6) rem(6) rem(10) rgba(0, 0, 0, 0.16);
}

.p-business__card {
  margin-top: rem(30);
  padding: 0 rem(12);

  @include mq(md) {
    width: 100%;
    flex: 1 0 0%;
    margin-top: 0;
    padding: 0 rem(44);
  }
}