@use "global" as *;

.c-button {
  color: #000;
  background-color: $white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(55);
  border: 1px solid #b3b3b3;
  font-weight: 300;
  font-size: rem(12);
  letter-spacing: 0.24em;
  font-family: $font-sub;

  @include mq(md) {
    width: rem(240);
  }
}

.c-button::after {
  content: "";
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/common/button-arrow-white.svg) no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  position: absolute;
  top: 50%;
  right: rem(-23);
  transform: translateY(-50%);
  width: rem(40);
  height: rem(4);
}

.c-button.ex {
  color: $white;
  background-color: transparent;
  border: 1px solid $white;

  @include mq(md) {
    width: rem(240);
  }
}

.c-button.ex::after {
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/common/button-arrow-clear.svg) no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  position: absolute;
  top: 50%;
  right: rem(-23);
  transform: translateY(-50%);
  width: rem(40);
  height: rem(4);
}