@use "global" as *;

.p-interview {
  padding-bottom: rem(50);

  @include mq(md) {
    padding-bottom: rem(100);
    position: relative;
  }
}

.p-interview::before {
  @include mq(md) {
    content: "";
    display: block;
    height: 80%;
    width: 15%;
    background-color: #123269;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.p-interview__title {
  width: rem(268);
  margin-left: auto;

  @include mq(md) {
    width: 100%;
  }
}

.p-interview__cards {
  @include mq(md) {
    display: flex;
  }
}

.p-interview__card:not(:last-of-type) {
  margin-bottom: rem(30);
}

.p-interview__card:nth-of-type(3n-1) {
  @include mq(md) {
    margin-top: rem(40);
  }
}

.p-interview__card:nth-of-type(3n) {
  @include mq(md) {
    margin-top: rem(80);
  }
}