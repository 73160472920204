@use "global" as *;

.c-work-card__img {
  aspect-ratio: 247/172;
}

.c-work-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-work-card__body {
  background-color: $white;
  padding: rem(15) rem(19) rem(20);
}

.c-work-card__title {
  border-bottom: 1px solid #707070;
  margin-bottom: rem(10);
  padding-bottom: rem(10);
  font-size: rem(15);
  line-height: 2;
  letter-spacing: 0.06em;
  color: #123269;

  @include mq(md) {
    border-bottom: 1px solid #123269;
    padding-bottom: rem(5);
    margin-bottom: rem(5);
  }
}

.c-work-card__text {
  font-size: rem(12);
  line-height: 2;
  letter-spacing: 0.06em;

  @include mq(md) {
    font-size: rem(11);
  }
}