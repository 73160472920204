@use "global" as *;

.p-order {
  padding-top: rem(40);

  @include mq(md) {
    padding-top: rem(100);
  }
}

.p-order__title {
  max-width: rem(230);

  @include mq(md) {
    max-width: 100%;
    text-align: center;
    margin-bottom: rem(40);
  }
}

.p-order__title img {
  @include mq(md) {
    margin: 0 auto;
  }
}

.p-order__table {
  @include mq(md) {
    width: 100%;
    max-width: rem(1066);
    margin: 0 auto;
  }
}

.p-order__table tr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: rem(20) 0;

  @include mq(md) {
    border-top: 1px solid #d9d9d9;
    width: 100%;
  }
}

.p-order__table th {
  width: 100%;
  margin-bottom: rem(20);

  @include mq(md) {

    width: rem(205);
    text-align: center;
    margin-bottom: 0;
  }
}

.p-order__table td {
  @include mq(md) {
    padding: rem(20) 0;
    width: rem(205);
    text-align: center;
  }
}

.p-order__table td:first-of-type {
  width: auto;
  font-size: rem(12);
  font-weight: 500;
  padding-right: 1.5em;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.12em;

  @include mq(md) {
    width: rem(205);
  }
}

.p-order__table td:nth-of-type(2) {
  color: $black;
  font-size: rem(12);
  line-height: 1;
  letter-spacing: 0.06em;

  @include mq(md) {
    text-align: left;
  }
}

.p-order__table td a {
  color: #c7c7c7;
  text-decoration: underline;
}