@use "global" as *;

.p-history {
  background-color: #eaeaea;
}

.p-history__img {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.p-history__body {
  padding: rem(30) 6.67%;
  position: relative;
  background-color: #123269;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  @include mq(md) {
    padding: rem(58) rem(75);
    width: 54.83%;
    margin: rem(-88) 0 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: calc(50% - 50vw);
  }
}

.p-history__title {
  margin-bottom: rem(25);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: $white;
  font-size: rem(24);
  letter-spacing: 0.15em;
  font-weight: 400;
}

.p-history__title span {
  font-size: rem(14);
  margin-bottom: rem(10);
  font-weight: 300;
}

.p-history__btn {
  @include mq(md) {
    margin-left: rem(90);
  }
}