@use "global" as *;


.c-chronology-card__img {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.c-chronology-card__body {
  padding: rem(56) 0;
}

.c-chronology-card__list {
  position: relative;
  width: 86.66%;
  margin: 0 auto;

  @include mq(md) {
    width: 100%;
  }
}

.c-chronology-card__list::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #1c3265;
}

.c-chronology-card__item {
  position: relative;
  padding-left: rem(35);
}

.c-chronology-card__item::before {
  content: "";
  display: block;
  position: absolute;
  left: rem(-5);
  width: rem(10);
  height: rem(10);
  border-radius: rem(5);
  background-color: #123269;

  @include mq(md) {
    top: rem(3);
  }
}

.c-chronology-card__title {
  font-size: rem(15);
  color: #123269;
  margin-bottom: rem(8);
  letter-spacing: 0.12em;
  line-height: 1.2;
}

.c-chronology-card__text {
  font-size: rem(13);
  letter-spacing: 0.11em;
  line-height: 1.8;
}

.c-chronology-card__item:nth-child(1n+2) {
  @include mq(md) {
    margin-top: rem(40);
  }
}