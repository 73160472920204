@use "global" as *;

.p-sdgs {
  background-color: #123269;
  color: $white;
}

.p-sdgs__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.p-sdgs__img {
  @include mq(md) {
    width: 66.6666667%;
    flex: 0 0 auto;
  }
}

.p-sdgs__body {
  padding: rem(40) 6.67vw;
  width: 100%;

  @include mq(md) {
    width: 33.33333333%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
  }
}

.p-sdgs__title {
  font-size: rem(25);
  letter-spacing: 0.2em;
  margin-bottom: rem(15);
}

.p-sdgs__text {
  margin-bottom: rem(40);
  font-size: rem(14);
  line-height: 2;
  letter-spacing: 0.2em;
}

.p-sdgs__btn {
  width: 100%;
}