@use "global" as *;

.p-fv {
  position: relative;
}

.p-fv__img {
  height: 100vh;

  @include mq(md) {
    height: auto;
  }
}

.p-fv__img picture img {
  height: 100vh;
  object-fit: cover;

  @include mq(md) {
    height: auto;
  }
}

.p-fv__body {
  width: 86.66%;
  position: absolute;
  left: 6.66%;
  bottom: 18.71%;
  color: $white;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  font-family: $font-sub;
  z-index: 10;

  @include mq(md) {
    width: 100%;
    left: 8.05%;
    bottom: 16.92%;
  }
}

.p-fv__title {
  letter-spacing: 0.15em;
  font-size: rem(25);
  margin-bottom: rem(20);
  line-height: 1.5;

  @include mq(md) {
    font-size: rem(36);
    margin-bottom: rem(30);
    letter-spacing: 0.19em;
  }
}

.p-fv__text {
  font-size: rem(14);
  letter-spacing: 0.05em;
  line-height: 2;

  @include mq(md) {
    font-size: rem(15);
  }
}