@use "global" as *;


.p-news-list {
  padding-top: rem(38);

  @include mq(md) {
    padding-top: rem(70);
    padding-bottom: rem(60);
  }
}

.p-news-list__wrapper {
  @include mq(md) {
    display: flex;
  }
}

.p-news-list__main {
  @include mq(md) {
    width: 66%;
    padding: 0 rem(38);
  }
}

.p-news-list__main.works {
  background-color: $white;
  padding: rem(40) rem(12);
  margin-top: rem(60);

  @include mq(md) {
    padding: rem(40) rem(38);
  }
}

.p-news-list__title {
  max-width: rem(182);


  @include mq(md) {
    max-width: 100%;
    margin-bottom: rem(85);
  }
}

.p-news-list__title.contact {
  max-width: rem(325);

  @include mq(md) {
    max-width: 100%;
  }
}

.p-news-list__title img {
  margin-bottom: rem(15);

  @include mq(md) {
    margin-bottom: rem(30);
  }
}

.p-news-list__title span {
  display: block;
  margin-top: rem(20);
  font-size: rem(25);
  line-height: 1;
  letter-spacing: 0.16em;
  font-weight: 400;
  font-family: $font-sub;

  @include mq(md) {
    font-family: $font-main;
  }
}

.p-news-list__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: rem(30);
  margin-bottom: rem(40);

  @include mq(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-news-list__card {
  border-bottom: 1px solid #e0e0e0;
  padding: rem(40) 0 rem(30) 0;
}

.p-news-list__pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(8);
}

.p-news-list__pagenation .page {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-sub;
  border: 1px solid #123269;
  font-size: rem(15);
  padding: rem(8) rem(10);
  background-color: #123269;
  color: $white;
}

.p-news-list__pagenation .larger {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-sub;
  color: #123269;
  border: 1px solid #123269;
  font-size: rem(15);
  padding: rem(8) rem(10);
}

.p-news-list__pagenation .next {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-sub;
  color: #123269;
  border: 1px solid #123269;
  font-size: rem(15);
  padding: rem(8) rem(10);
}

.p-news-list__aside {
  margin-top: rem(60);

  @include mq(md) {
    width: 33%;
    padding: 0 rem(38);
    margin-top: rem(40);
  }
}