@use "global" as *;


.p-contact-form {
  margin-top: rem(40);

  @include mq(md) {
    background-color: $white;
    padding: rem(60);
  }
}

.p-contact-form__text {
  margin-bottom: rem(30);
  font-size: rem(15);
  letter-spacing: 0.16em;
  font-weight: bold;
  color: #123269;

  @include mq(md) {
    margin-bottom: rem(40);
    font-size: rem(20);
    letter-spacing: 0.06em;
    text-align: center;
  }
}

.p-contact-form__box {
  margin-bottom: rem(30);
  padding: rem(20);
  background-color: $white;

  @include mq(md) {
    margin-bottom: rem(20);
    padding: rem(25);
    border: 1px solid #f0f0f0;

  }
}

.p-contact-form__box div {
  @include mq(md) {
    height: rem(80);
    overflow-y: scroll;
  }
}

.p-contact-form__lead {
  font-size: rem(12);
  font-weight: 600;
  color: #02436b;
  margin-bottom: rem(10);

  @include mq(md) {
    font-size: rem(14);
  }
}

.p-contact-form__list li {
  position: relative;
  font-size: rem(10);
  padding-left: 1.8em;
  line-height: 2;
}

.p-contact-form__list li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "・";
}