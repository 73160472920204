@use "global" as *;

.p-greeting__title img {
  max-width: rem(288);
}

.p-greeting__card {
  margin-top: rem(50);
}

.p-greeting__wrapper {
  display: none;

  @include mq(md) {
    display: flex;
  }
}

.p-greeting__col {
  width: 41.66666667%;
  flex: 0 0 auto;
  padding: 0 rem(50);
}

.p-greeting__col-card {
  margin-top: rem(132);
}

.p-greeting__imgarea {
  flex: 0 0 auto;
  width: 58.33333333%;
  padding-left: rem(50);
}

.p-greeting__img {
  position: sticky;
  top: rem(150);
}