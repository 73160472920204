@use "global" as *;

.p-header {
  background-color: $white;
  padding: rem(20) rem(20) rem(20) rem(25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @include mq(md) {
    background-color: transparent;
    position: absolute;
    top: rem(20);
    left: 0;
    width: 100%;
    padding: 0 rem(50);
  }
}

.p-header.page {
  color: $black;
}

.p-header__title img {
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.16));
}

.p-header__toggle {
  position: relative;
  width: rem(25);
  height: rem(16);
  cursor: pointer;
  z-index: 500;

  @include mq(md) {
    display: none;
  }
}

.p-header__toggle span {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: #000;
  transition: .5s;
}

.p-header__toggle.show span {
  background-color: $white;
}

.p-header__toggle span:nth-child(1) {
  top: 0;
}

.p-header__toggle span:nth-child(2) {
  top: rem(7);
}

.p-header__toggle span:nth-child(3) {
  bottom: 0;
}

.p-header__toggle.show span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.p-header__toggle.show span:nth-child(2) {
  display: none;
}

.p-header__toggle.show span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.p-header__nav {
  display: none;

  @include mq(md) {
    display: flex;
    align-items: center;
  }
}

.p-header__list {
  display: flex;
  color: $white;
}

.p-header__list.page {
  color: $black;
}

.p-header__item {
  font-size: rem(14);
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  padding-left: rem(20);
  padding-right: rem(20);
  letter-spacing: 0.19em;
  font-family: $font-sub;
}

.p-header__link {
  width: rem(145);
  height: rem(33);
  font-size: rem(12);
  letter-spacing: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: #123269;
  margin-left: rem(30);
}

.p-header__link span {
  padding-right: rem(33);
  background: url(https://www.e-kanbe.com/wp/wp-content/themes/e-kanbe/img/common/nav-arrow.svg) no-repeat;
  background-position: right 0 center;
}

.p-header__menu {
  display: none;
}

.p-header__menu.show {
  display: block;
}