@use "global" as *;

.p-menu {
  background-color: #123269;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: $white;
}

.p-menu__wrapper {
  display: flex;
  flex-direction: column;
}

.p-menu__title {
  margin: rem(20) 0 rem(78) rem(25);
}

.p-menu__title img {
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.16));
}

.p-menu__list {
  margin-bottom: rem(42);
}

.p-menu__item a {
  letter-spacing: 0.16em;
}

.p-menu__item a::before {
  content: "-";
}

.p-menu__item+.p-menu__item {
  margin-top: rem(16);
}

.p-menu__sns {
  font-size: rem(18);
  letter-spacing: 0.05em;
  margin-bottom: rem(40);
}

.p-menu__sns a {
  display: inline-block;
  margin-top: rem(10);
}

.p-menu__sns a:nth-of-type(1n+2) {
  margin-left: rem(10);
}

.p-menu__sns a img {
  width: rem(40);
}

.p-menu__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $white;
  font-size: rem(14);
  letter-spacing: 0.24em;
  width: 100%;
  height: rem(52);
}

.p-menu__btn span {
  display: inline-flex;
  align-items: center;
}

.p-menu__btn span img {
  margin-right: rem(10);
}

.p-menu__btn+.p-menu__btn {
  margin-top: rem(18);
}

.p-menu__bottom {
  margin-top: rem(25);
  font-size: rem(13);
  letter-spacing: 0.15em;
}