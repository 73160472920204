@use "global" as *;

.p-company {
  padding: rem(140) 0 rem(50) 0;
  background-color: #eaeaea;

  @include mq(md) {
    padding: rem(100) 0 rem(120) 0;
  }
}

.p-company__wrapper {
  display: flex;
  flex-direction: column;

  @include mq(md) {
    flex-direction: row;
  }
}

.p-company__card {
  order: 2;
  margin-top: rem(30);

  @include mq(md) {
    order: 1;
    width: 38.03%;
    flex: 1 0 0%;
    margin-top: 0;
  }
}

.p-company__img {
  order: 1;
  margin-top: rem(30);
  padding: 0 rem(12);

  @include mq(md) {
    order: 2;
    width: 49.23%;
    padding: 0 rem(44);
    flex: 1 0 0%;
    margin-top: 0;
  }
}

.p-company__img img {
  @include mq(md) {
    height: 100%;
    object-fit: cover;
  }
}