@use 'global' as *;

.p-contact {
  padding: rem(45) 0;
  background-color: #eaeaea;
  color: $white;

  @include mq(md) {
    padding: rem(118) 0 rem(80) 0;
  }
}

.p-contact.news {
  background-color: $white;
}

.p-contact__wrapper {
  background-color: #123269;
  padding-bottom: rem(40);

  @include mq(md) {
    padding-top: rem(50);
    padding-bottom: rem(50);
    display: flex;
    flex-wrap: wrap;
  }
}

.p-contact__card {
  padding-top: rem(40);

  @include mq(md) {
    width: 50%;
  }
}