@use "global" as *;

.p-page-fv {
  margin-top: rem(100);
  margin-bottom: rem(40);
  position: relative;
  overflow: hidden;

  @include mq(md) {
    margin-top: 0;
    padding-left: rem(150);
    margin-bottom: rem(100);
  }
}

.p-page-fv__title {
  position: absolute;
  top: 50%;
  left: rem(50);
  transform: translateY(-50%);
  color: $white;
  font-size: rem(25);
  line-height: 1;
  letter-spacing: 0.16em;
  font-weight: 400;
  z-index: 10;

  @include mq(md) {
    position: static;
    transform: translateY(0);
    margin: rem(85) 0;
    padding: 0 rem(30);
    color: $black;
  }
}

.p-page-fv__title img {
  display: none;

  @include mq(md) {
    display: block;
    margin-bottom: rem(30);
  }
}

.p-page-fv__img {
  @include mq(md) {
    overflow: hidden;
  }
}

.p-page-fv__img img {
  transform: translate3d(0px, -26px, 0px) scale(1.3);
  will-change: transform;

  @include mq(md) {
    transform: translate3d(0px, -14px, 0px) scale(1.3);
    will-change: transform;
    width: 100%;
  }
}