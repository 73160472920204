@use "global" as *;

.c-about-sdgs-card__body {
  padding: rem(25) rem(20) rem(50);

  @include mq(md) {
    width: 55%;
    flex: 0 0 auto;
    padding: 0;
  }
}

.c-about-sdgs-card__title {
  margin-bottom: rem(10);
  color: #123269;
  font-weight: bold;
  font-size: rem(20);
  line-height: 2;
  letter-spacing: 0.06em;

  @include mq(md) {
    margin-bottom: rem(25);
  }
}

.c-about-sdgs-card__text {
  margin-bottom: 1em;
  line-height: 2.3;
  font-size: rem(12);
  letter-spacing: 0.06em;
}

.c-about-sdgs-card__img {
  @include mq(md) {
    width: 45%;
    padding-right: rem(60);
    padding-left: rem(44);
    flex: 0 0 auto;
  }
}