@use 'global' as *;


.p-works__item {
  margin-bottom: rem(30);
  padding-bottom: rem(30);
  border-bottom: 1px solid #e0e0e0;
}

.p-works__item a {
  display: block;

  @include mq(md) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.p-works__item h3 {
  margin-bottom: rem(10);
  font-size: rem(12);
  letter-spacing: 0.06em;
  font-weight: 500;
  line-height: 1.2;

  @include mq(md) {
    font-size: rem(14);
    margin-right: rem(25);
    letter-spacing: 0.3em;
  }
}

.p-works__cate {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.p-works__cate span {
  color: #123269;
  border: 1px solid #123269;
  padding: 1px rem(10);
  font-size: rem(11);
  letter-spacing: 0.05em;
}