@use "global" as *;

.p-outline {
  padding: rem(80) 0;
  background-color: #eaeaea;
}

.p-outline__title {
  max-width: rem(230);
  margin-bottom: rem(40);

  @include mq(md) {
    max-width: 100%;
  }
}

.p-outline__table {
  border-collapse: collapse;

  @include mq(md) {
    width: 100%;
  }
}

.p-outline__table th {
  width: rem(150);
  color: #123269;
  padding: rem(30) rem(20);
  border-width: rem(2) 0;
  border-style: solid;
  border-color: $white;
  font-size: rem(13);
  letter-spacing: 0.12em;
  line-height: 2;
  text-align: left;
}

.p-outline__table td {
  padding: rem(30) rem(20);
  border-width: rem(2) 0;
  border-style: solid;
  border-color: $white;
  font-size: rem(13);
  letter-spacing: 0.12em;
  line-height: 2;
}

.p-outline__table td a {
  color: #0d6efd;
  text-decoration: underline;
}