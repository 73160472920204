@use "global" as *;

.p-about-sdgs {
  padding-top: rem(25);
  padding-bottom: rem(50);
  background-color: #eaeaea;

  @include mq(md) {
    padding: rem(100) 0;
  }
}

.p-about-sdgs__inner {
  background-color: $white;

  @include mq(md) {
    padding: rem(60) rem(80) rem(70);
  }
}

.p-about-sdgs__title {
  padding: rem(25);
  text-align: center;

  @include mq(md) {
    margin-bottom: rem(70);
  }
}

.p-about-sdgs__title img {
  margin: 0 auto;
}

.p-about-sdgs__card {
  @include mq(md) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
  }
}